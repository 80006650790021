body.menu-active .page {
  @include bp(max, $bp-2) {
    overflow: hidden;
    height: 100%; } }
.page {
  @include clearfix;
  width: 100vw;
  background-color: $white;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch; }
.main {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  @include bp(min, $bp-3) {
    padding: 0; } }
