.gallery {
  @include unit(max-width, 10);
  @include bp(min, $bp-2) {
    @include unit(max-width, 8); }
  @include bp(min, $bp-4) {
    @include unit(max-width, 6); }
  @include bp(min, $bp-3) {
    @include unit(max-width, 10);
    border-collapse: collapse;
    display: table; } }
.gallery--top .gallery__item {
  @include unit(margin-bottom, .5);
  vertical-align: bottom;
  order: 2;
  &.has-caption {
    order: 1; }
  @include bp(min, $bp-3) {
    margin: 0; } }
.gallery--top .gallery__item:nth-child(1) {
  @include bp(min, $bp-3) {
    @include unit(max-width, 3); } }
.gallery--top .gallery__item:nth-child(2) {
  @include bp(min, $bp-3) {
    @include unit(max-width, 3); } }
.gallery--top .gallery__item:nth-child(3) {
  @include bp(min, $bp-3) {
    @include unit(max-width, 3.75); } }
.gallery--bottom .gallery__item:nth-child(1) {
  @include bp(min, $bp-3) {
    @include unit(max-width, 5.5); } }
.gallery--bottom .gallery__item:nth-child(2) {
  @include bp(min, $bp-3) {
    @include unit(max-width, 3); } }
.gallery--bottom .gallery__item:nth-child(3) {
  @include bp(min, $bp-3) {
    @include unit(max-width, 3); } }
.gallery--bottom .gallery__item {
  display: none;
  @include bp(min, $bp-3) {
    vertical-align: top;
    display: table-cell; } }
.gallery--bottom .gallery__item:only-child {
  @include bp(min, $bp-3) {
    @include unit(max-width, 4);
    display: block;
    margin: 0 auto; } }
.gallery__item {
  position: relative;
  img {
    display: block;
    width: 100%;
    height: auto; }
  @include bp(min, $bp-3) {
    display: table-cell;
    padding: 15px; } }
.gallery__caption {
  display: table;
  width: 100%;
  p {
    width: 100%; } }
.gallery__caption-text {
  display: table-caption; }
.gallery__credits {
  position: absolute;
  padding: 0 2px;
  bottom: 17px;
  right: 17px;
  background-color: rgba(255,255,255,.7);
  font-size: 10px;
  &::before {
    content: '\00a9';
    display: inline-block;
    padding-right: 2px; } }
