$colors: false;
$colors-list: #f5f5f5, #dedede, #c0c0c0, #9c9c9c, #818181, #585858, #383838, #232323;

.piechart {
  width: 130px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #000;
  padding: 5px;
  display: inline-block;
  &::before {
    content: "";
    display: block;
    padding-top: 100%; }
  @include bp(min, $bp-3) {
    @include unit(width, 1); } }

@each $current-color in $colors-list {
  $i: index($colors-list, $current-color);
  .piechart__description:nth-child(#{$i}) {
    &::before {
      background-color: $current-color; } }
  svg .piechart__slice:nth-of-type(#{$i}) {
    fill: $current-color; }
  .piechart--#{$i} .piechart__slice:nth-of-type(n+#{$i + 1}) {
    display: none; } }

.piechart__container {
  width: 100%;
  text-align: center; }
.piechart__headline {
  width: 100%;
  text-align: center; }
.piechart__description-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  @include unit(max-width, 11);
  overflow-x: scroll;
  @include bp(min, $bp-2) {
    @include unit(max-width, 12);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: auto; } }
.piechart__description {
  text-align: center;
  padding: 20px;
  // +unit(min-width, 4.5)
  // +bp(min, $bp-2)
  //   +unit(min-width, 3)
  // +bp(min, $bp-3)
  //   +unit(min-width, 2)
  // +bp(min, $bp-5)
  //   +unit(min-width, 1.5)
  &::before {
    display: inline-block;
    content: '';
    width: 15px;
    height: 15px; } }
.piechart__legend {
  font-size: 12px; }
.piechart__legend--small {
  font-size: 8px;
  line-height: 1.8;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .15rem; }
