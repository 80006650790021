.project__header {
  flex-wrap: wrap;
  background-color: #f1f1f1;
  flex-direction: column;
  justify-content: flex-start; }
.project__header-image {
  text-align: center;
  margin-top: -.9em;
  @include unit(width, 6);
  @include bp(min, $bp-4) {
    @include unit(width, 3); } }
.project__header-title {
  font-size: 32px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.1;
  z-index: 1;
  @include bp(min, $bp-4) {
    font-size: 38px; } }
.project__header-year,
.project__header-subtitle {
  text-align: center;
  line-height: 1.8;
  font-size: 10px;
  padding: 10px 0;
  font-weight: 500; }
.project__icons-wrapper {
  overflow-x: auto; }
.project__icons {
  @include unit(max-width, 12);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.project__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  flex: 0 1 100px;
  @include unit(min-width, 3);
  @include unit(max-width, 4);
  @include bp(max, $bp-2) {
    font-size: 12px;
    @include unit(min-width, 5); }
  @include bp(min, $bp-2) {
    @include unit(min-width, 3); }
  @include bp(min, $bp-3) {
    @include unit(min-width, 2); }
  @include bp(min, $bp-5) {
    @include unit(min-width, 1.5); } }
.project__icon-title {
  font-size: 10px;
  padding: 10px 0;
  font-weight: 500;
  @include bp(max, $bp-2) {
    font-size: 8px; } }
.project__description-headline {
  text-align: center;
  margin: 0 auto;
  @include unit(padding-bottom, .75);
  @include unit(max-width, 12);
  @include bp(min, $bp-2) {
    @include unit(padding-left, 1.5);
    @include unit(padding-right, 1.5); }
  @include bp(min, $bp-4) {
    @include unit(padding-left, 3);
    @include unit(padding-right, 3);
    margin: 0; } }
.project__type {
  display: block;
  &::before,
  &::after {
    content: '—';
    display: inline-block;
    padding: 1em; } }
