.video {
  @include unit(width, 10);
  @include bp(min, $bp-2) {
    @include unit(width, 8); }
  @include bp(min, $bp-4) {
    @include unit(width, 6); } }
.video__container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
.video__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
.video__backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1; }
.video__button {
  backface-visibility: hidden;
  border-radius: 50%;
  background-color: $black;
  color: $white;
  font-size: 10px;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  transition: all .25s ease-in-out;
  z-index: 1;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 0 2px #fff;
    transform: translate(-50%, -52%); } }
.video__description {
  font-weight: 300;
  line-height: 1.6;
  @include unit(padding-top, .5);
  @include unit(padding-left, .5);
  @include unit(padding-right, .5); }
