
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal; }
