@mixin font-size($size) {
  font-size: rem($size);
  @if $size == $font-size-s {
    line-height: $line-height-s; }
  @if $size == $font-size-m {
    line-height: $line-height-m; }
  @if $size == $font-size-ml {
    line-height: $line-height-ml; }
  @if $size == $font-size-l {
    line-height: $line-height-l; }
  @if $size == $font-size-xl {
    line-height: $line-height-xl; } }

@mixin line-height($base, $breakpoints, $sizes) {
  line-height: rem($base);
  $i: 0;
  @each $break in $breakpoints {
    $i: $i + 1;
    @include bp(min, $break) {
      line-height: rem(nth($sizes, $i)); } } }

@mixin type-zoom($base-size, $breakpoints, $sizes) {
  @include font-size($base-size);
  $i: 1;
  @each $break in $breakpoints {
    @include bp(min, $break) {
      @include font-size(nth($sizes, $i)); }
    $i: $i + 1; } }

.type-s {
  @include font-size($font-size-s); }
.type-m {
  @include font-size($font-size-m); }
.type-ml {
  @include font-size($font-size-ml); }
.type-l {
  @include font-size($font-size-l); }
.type-xl {
  @include font-size($font-size-xl); }

@mixin type-xs-to-s {
  @include type-zoom($font-size-xs, $bp-3, $font-size-s); }
.type-xs-to-s {
  @include type-xs-to-s; }

@mixin type-s-to-ml {
  @include type-zoom($font-size-s, $bp-3 $bp-4, $font-size-m $font-size-ml); }
.type-s-to-ml {
  @include type-s-to-ml; }

@mixin type-xs-to-m {
  @include type-zoom($font-size-xs, $bp-3 $bp-4, $font-size-s $font-size-m); }
.type-xs-to-m {
  @include type-xs-to-m; }

@mixin type-m-to-ml {
  @include type-zoom($font-size-m, $bp-3, $font-size-ml); }
.type-m-to-ml {
  @include type-m-to-ml; }

@mixin type-s-to-ml--smaller {
  @include type-zoom($font-size-s, $bp-3, 18); }
.type-s-to-ml--smaller {
  @include type-s-to-ml--smaller; }

@mixin type-m-to-l {
  @include type-zoom($font-size-m, $bp-2 $bp-3, $font-size-ml $font-size-l); }
.type-m-to-l {
  @include type-m-to-l; }

@mixin type-m-to-xl {
  @include type-zoom($font-size-m, $bp-2 $bp-3 $bp-4, $font-size-ml $font-size-l $font-size-xl); }
.type-m-to-xl {
  @include type-m-to-xl; }

@mixin type-s-to-m {
  @include type-zoom($font-size-s, $bp-3, $font-size-m); }
.type-s-to-m {
  @include type-s-to-m; }

@mixin type-l-to-xl {
  @include type-zoom($font-size-l, $bp-3, $font-size-xl); }
.type-l-to-xl {
  @include type-l-to-xl; }

@mixin type-l-to-xxl {
  @include type-zoom($font-size-l, $bp-3 $bp-4, $font-size-xl $font-size-xxl); }
.type-l-to-xxl {
  @include type-l-to-xxl; }

@mixin type-ml-to-xl {
  @include type-zoom($font-size-ml, $bp-3 $bp-4, $font-size-l $font-size-xl); }
.type-ml-to-xl {
  @include type-ml-to-xl; }

@mixin type-ml-to-xxl {
  @include type-zoom($font-size-ml, $bp-2 $bp-3 $bp-4, $font-size-l $font-size-xl $font-size-xxl); }
.type-ml-to-xxl {
  @include type-ml-to-xxl; }

@mixin type-ml-to-xxxl {
  @include type-zoom($font-size-ml, $bp-2 $bp-3 $bp-4 $bp-5, $font-size-l $font-size-xl $font-size-xxl $font-size-xxxl); }
.type-ml-to-xxxl {
  @include type-ml-to-xxxl; }

@mixin type-ml-to-l {
  @include type-zoom($font-size-ml, $bp-3, $font-size-l); }
.type-ml-to-l {
  @include type-ml-to-l; }

.text-bold {
  font-weight: 500; }

.text-center {
  text-align: center; }

.text-link {
  text-decoration: underline; }

.text-uppercase {
  text-transform: uppercase; }

.text-spaced {
  letter-spacing: .2rem; }

.text-slanted {
  text-align: center;
  display: block;
  font-style: italic; }
