@charset "UTF-8";
* {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFF; }

body {
  background-color: #FFF;
  margin: 0;
  font-size: 16px;
  font-family: "Graphik", Helvetica, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  font-weight: normal;
  position: relative;
  overflow-x: hidden;
  padding: 0;
  display: flex;
  min-height: 100vh; }

h1, h2, h3, h4, h5, h6, blockquote, figure, ol, ul {
  font-weight: 500;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: bold; }

ul {
  list-style: none; }

ol, ul, p {
  padding: 0;
  margin: 0; }

p {
  line-height: 1.6; }

sup {
  line-height: 1; }

a {
  text-decoration: underline;
  color: inherit; }
  a:hover {
    text-decoration: line-through; }

img {
  max-width: 100%;
  height: auto;
  border: 0; }

iframe {
  border: 0; }

form fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

select,
input {
  border-radius: 0;
  font-family: "Graphik", Helvetica, Arial, sans-serif; }

input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

textarea {
  vertical-align: top;
  font-family: "Graphik", Helvetica, Arial, sans-serif; }

address, i, em {
  font-style: normal; }

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.75); }

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.75); }

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.75); }

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75); }

::-moz-selection {
  background-color: #000;
  color: #FFF;
  text-shadow: none; }

::selection {
  background-color: #000;
  color: #FFF;
  text-shadow: none; }

.type-s {
  font-size: 0.875rem;
  line-height: 1.3; }

.type-m {
  font-size: 1rem;
  line-height: 1.3; }

.type-ml {
  font-size: 1.25rem;
  line-height: 1.3; }

.type-l {
  font-size: 1.875rem;
  line-height: 1.2; }

.type-xl {
  font-size: 2.5rem;
  line-height: 1.2; }

.type-xs-to-s {
  font-size: 0.625rem; }
  @media screen and (min-width: 48em) {
    .type-xs-to-s {
      font-size: 0.875rem;
      line-height: 1.3; } }

.type-s-to-ml {
  font-size: 0.875rem;
  line-height: 1.3; }
  @media screen and (min-width: 48em) {
    .type-s-to-ml {
      font-size: 1rem;
      line-height: 1.3; } }
  @media screen and (min-width: 64em) {
    .type-s-to-ml {
      font-size: 1.25rem;
      line-height: 1.3; } }

.type-xs-to-m {
  font-size: 0.625rem; }
  @media screen and (min-width: 48em) {
    .type-xs-to-m {
      font-size: 0.875rem;
      line-height: 1.3; } }
  @media screen and (min-width: 64em) {
    .type-xs-to-m {
      font-size: 1rem;
      line-height: 1.3; } }

.type-m-to-ml {
  font-size: 1rem;
  line-height: 1.3; }
  @media screen and (min-width: 48em) {
    .type-m-to-ml {
      font-size: 1.25rem;
      line-height: 1.3; } }

.type-s-to-ml--smaller {
  font-size: 0.875rem;
  line-height: 1.3; }
  @media screen and (min-width: 48em) {
    .type-s-to-ml--smaller {
      font-size: 1.125rem; } }

.type-m-to-l {
  font-size: 1rem;
  line-height: 1.3; }
  @media screen and (min-width: 36em) {
    .type-m-to-l {
      font-size: 1.25rem;
      line-height: 1.3; } }
  @media screen and (min-width: 48em) {
    .type-m-to-l {
      font-size: 1.875rem;
      line-height: 1.2; } }

.type-m-to-xl {
  font-size: 1rem;
  line-height: 1.3; }
  @media screen and (min-width: 36em) {
    .type-m-to-xl {
      font-size: 1.25rem;
      line-height: 1.3; } }
  @media screen and (min-width: 48em) {
    .type-m-to-xl {
      font-size: 1.875rem;
      line-height: 1.2; } }
  @media screen and (min-width: 64em) {
    .type-m-to-xl {
      font-size: 2.5rem;
      line-height: 1.2; } }

.type-s-to-m {
  font-size: 0.875rem;
  line-height: 1.3; }
  @media screen and (min-width: 48em) {
    .type-s-to-m {
      font-size: 1rem;
      line-height: 1.3; } }

.type-l-to-xl {
  font-size: 1.875rem;
  line-height: 1.2; }
  @media screen and (min-width: 48em) {
    .type-l-to-xl {
      font-size: 2.5rem;
      line-height: 1.2; } }

.type-l-to-xxl {
  font-size: 1.875rem;
  line-height: 1.2; }
  @media screen and (min-width: 48em) {
    .type-l-to-xxl {
      font-size: 2.5rem;
      line-height: 1.2; } }
  @media screen and (min-width: 64em) {
    .type-l-to-xxl {
      font-size: 4.375rem; } }

.type-ml-to-xl {
  font-size: 1.25rem;
  line-height: 1.3; }
  @media screen and (min-width: 48em) {
    .type-ml-to-xl {
      font-size: 1.875rem;
      line-height: 1.2; } }
  @media screen and (min-width: 64em) {
    .type-ml-to-xl {
      font-size: 2.5rem;
      line-height: 1.2; } }

.type-ml-to-xxl {
  font-size: 1.25rem;
  line-height: 1.3; }
  @media screen and (min-width: 36em) {
    .type-ml-to-xxl {
      font-size: 1.875rem;
      line-height: 1.2; } }
  @media screen and (min-width: 48em) {
    .type-ml-to-xxl {
      font-size: 2.5rem;
      line-height: 1.2; } }
  @media screen and (min-width: 64em) {
    .type-ml-to-xxl {
      font-size: 4.375rem; } }

.type-ml-to-xxxl {
  font-size: 1.25rem;
  line-height: 1.3; }
  @media screen and (min-width: 36em) {
    .type-ml-to-xxxl {
      font-size: 1.875rem;
      line-height: 1.2; } }
  @media screen and (min-width: 48em) {
    .type-ml-to-xxxl {
      font-size: 2.5rem;
      line-height: 1.2; } }
  @media screen and (min-width: 64em) {
    .type-ml-to-xxxl {
      font-size: 4.375rem; } }
  @media screen and (min-width: 80em) {
    .type-ml-to-xxxl {
      font-size: 5.625rem; } }

.type-ml-to-l {
  font-size: 1.25rem;
  line-height: 1.3; }
  @media screen and (min-width: 48em) {
    .type-ml-to-l {
      font-size: 1.875rem;
      line-height: 1.2; } }

.text-bold {
  font-weight: 500; }

.text-center {
  text-align: center; }

.text-link {
  text-decoration: underline; }

.text-uppercase {
  text-transform: uppercase; }

.text-spaced {
  letter-spacing: .2rem; }

.text-slanted {
  text-align: center;
  display: block;
  font-style: italic; }

.body-text {
  margin: 0 auto;
  margin-bottom: 0em;
  max-width: 750px; }
  .body-text h1, .body-text h2 {
    font-weight: bold;
    margin-bottom: 2em; }
  .body-text p,
  .body-text ul, .body-text ol, .body-text address {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: normal; }
    @media screen and (min-width: 48em) {
      .body-text p,
      .body-text ul, .body-text ol, .body-text address {
        font-size: 1.25rem;
        line-height: 1.3; } }
  .body-text p, .body-text address {
    position: relative;
    margin-bottom: 1em; }
    .body-text p strong, .body-text p b, .body-text address strong, .body-text address b {
      font-weight: bold; }
    .body-text p em, .body-text p i, .body-text address em, .body-text address i {
      font-style: italic; }
    .body-text p:last-child, .body-text address:last-child {
      margin-bottom: 0; }
  .body-text h1 {
    font-size: 1.875rem;
    line-height: 1.2;
    margin-bottom: 2em;
    margin-top: 0; }
    @media screen and (min-width: 48em) {
      .body-text h1 {
        font-size: 2.5rem;
        line-height: 1.2; } }
  .body-text h2 {
    font-size: 1.25rem;
    line-height: 1.3;
    margin-bottom: 1.5em;
    margin-top: 3em; }
    @media screen and (min-width: 48em) {
      .body-text h2 {
        font-size: 1.875rem;
        line-height: 1.2; } }
  .body-text h3, .body-text h4, .body-text h5, .body-text h6 {
    font-size: 1rem;
    line-height: 1.3;
    margin-top: 2em;
    margin-bottom: 1em; }
    @media screen and (min-width: 48em) {
      .body-text h3, .body-text h4, .body-text h5, .body-text h6 {
        font-size: 1.25rem;
        line-height: 1.3; } }
  .body-text strong {
    font-weight: bold !important; }
  .body-text figure {
    margin-top: 2em;
    margin-bottom: 2em; }
    .body-text figure img {
      width: auto; }
  .body-text ul, .body-text ol {
    margin: 1.5em auto 1.5em auto; }
    .body-text ul li, .body-text ol li {
      margin-bottom: .6em; }
  .body-text ol li {
    position: relative;
    list-style-position: inside;
    list-style-type: none;
    padding-left: 2em;
    counter-increment: list-item; }
    .body-text ol li:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5em;
      content: counter(list-item) ".";
      font-size: 90%;
      text-align: right;
      font-weight: bold; }
  .body-text ul li {
    position: relative;
    padding-left: 1.1em; }
    .body-text ul li:before {
      content: '•';
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 0; }
  .body-text a {
    -webkit-transition: color, 0.25s;
    -moz-transition: color, 0.25s;
    transition: color, 0.25s; }
    .body-text a:hover {
      text-decoration: line-through; }
  .body-text.is-small {
    max-width: none; }
    .body-text.is-small p,
    .body-text.is-small h3, .body-text.is-small h4, .body-text.is-small h5, .body-text.is-small h6,
    .body-text.is-small ul, .body-text.is-small ol, .body-text.is-small address {
      font-size: 0.875rem;
      line-height: 1.3; }
      @media screen and (min-width: 48em) {
        .body-text.is-small p,
        .body-text.is-small h3, .body-text.is-small h4, .body-text.is-small h5, .body-text.is-small h6,
        .body-text.is-small ul, .body-text.is-small ol, .body-text.is-small address {
          font-size: 1rem;
          line-height: 1.3; } }
  .body-text.is-large {
    max-width: none; }
    .body-text.is-large p,
    .body-text.is-large h3, .body-text.is-large h4, .body-text.is-large h5, .body-text.is-large h6,
    .body-text.is-large ul, .body-text.is-large ol, .body-text.is-large address {
      font-size: 1.25rem;
      line-height: 1.3; }
      @media screen and (min-width: 48em) {
        .body-text.is-large p,
        .body-text.is-large h3, .body-text.is-large h4, .body-text.is-large h5, .body-text.is-large h6,
        .body-text.is-large ul, .body-text.is-large ol, .body-text.is-large address {
          font-size: 1.875rem;
          line-height: 1.2; } }
  .body-text.has-offsets {
    max-width: 850px; }
    @media screen and (min-width: 36em) {
      .body-text.has-offsets p, .body-text.has-offsets ul, .body-text.has-offsets ol, .body-text.has-offsets figure {
        padding-left: 8.33333vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .body-text.has-offsets p, .body-text.has-offsets ul, .body-text.has-offsets ol, .body-text.has-offsets figure {
      padding-left: 116.66667px; } }
    @media screen and (min-width: 36em) {
      .body-text.has-offsets h1, .body-text.has-offsets h2, .body-text.has-offsets h3, .body-text.has-offsets h4, .body-text.has-offsets h5, .body-text.has-offsets h6 {
        padding-right: 8.33333vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .body-text.has-offsets h1, .body-text.has-offsets h2, .body-text.has-offsets h3, .body-text.has-offsets h4, .body-text.has-offsets h5, .body-text.has-offsets h6 {
      padding-right: 116.66667px; } }

@media screen and (max-width: 36em) {
  body.menu-active .page {
    overflow: hidden;
    height: 100%; } }

.page {
  width: 100vw;
  background-color: #FFF;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch; }
  .page::after {
    clear: both;
    content: "";
    display: table; }

.main {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 48em) {
    .main {
      padding: 0; } }

/* Z-INDEX */
.has-debug-info .grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: block;
  width: 100%;
  height: 100%;
  background-position: 0 0;
  background-image: linear-gradient(90deg, aqua 1px, transparent 1px);
  background-size: calc(8.33333vw + 1px) 2px;
  overflow-x: hidden;
  pointer-events: none; }
  @media screen and (min-width: 87.5em) {
    .has-debug-info .grid-overlay {
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 1400px;
      left: 50%;
      background-size: calc(116.66667px + 1px) 2px; } }
  .has-debug-info .grid-overlay:before, .has-debug-info .grid-overlay:after {
    width: 8.33333vw;
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    background-color: rgba(245, 255, 250, 0.4); }
    @media screen and (min-width: 87.5em) {
      .has-debug-info .grid-overlay:before, .has-debug-info .grid-overlay:after {
        width: 116.66667px; } }
  .has-debug-info .grid-overlay:before {
    left: 0; }
  .has-debug-info .grid-overlay:after {
    right: -6px; }

.has-debug-info * {
  outline: 1px solid hotpink; }

@font-face {
  font-family: 'Graphik';
  src: url("../fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Graphik';
  src: url("../fonts/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Graphik';
  src: url("../fonts/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

.slide-up-transition {
  transition: all .3s ease;
  will-change: transform; }

.slide-up-enter, .slide-up-leave {
  transform: translateY(100%) translateZ(0px); }

.fade-transition {
  transition: opacity .3s ease; }

.fade-enter, .fade-leave {
  opacity: 0; }

@-webkit-keyframes uil-default-anim {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes uil-default-anim {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.uil-default-css > div:nth-of-type(1) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
  flex: 1 1 100%;
  padding: .5em 1em;
  margin: 1em auto; }
  .uil-default-css.is-hidden {
    display: none; }

.uil-default-css > div:nth-of-type(2) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.4166666666666667s;
  animation-delay: -0.4166666666666667s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(3) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.33333333333333337s;
  animation-delay: -0.33333333333333337s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(4) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(5) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.16666666666666669s;
  animation-delay: -0.16666666666666669s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(6) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.08333333333333331s;
  animation-delay: -0.08333333333333331s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(7) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(8) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.08333333333333337s;
  animation-delay: 0.08333333333333337s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(9) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.16666666666666663s;
  animation-delay: 0.16666666666666663s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(10) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(11) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.33333333333333337s;
  animation-delay: 0.33333333333333337s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.uil-default-css > div:nth-of-type(12) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.41666666666666663s;
  animation-delay: 0.41666666666666663s; }

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px; }

.boilerplate {
  display: flex;
  flex-wrap: wrap; }

.boilerplate--center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  @media screen and (min-width: 48em) {
    .boilerplate--center {
      align-items: center; } }

.boilerplate__headline {
  line-height: 1.6;
  font-weight: 400;
  font-size: 19px;
  max-width: 25vw; }
  @media screen and (min-width: 87.5em) {
    .boilerplate__headline {
      max-width: 350px; } }

.boilerplate__button {
  text-decoration: none; }
  @media screen and (min-width: 48em) {
    .boilerplate__button {
      border-width: 1px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.7);
      transition: border-color .25s ease;
      padding: 10px 20px; } }
  .boilerplate__button:hover {
    border-color: white; }

.boilerplate__link {
  text-decoration: none;
  margin-bottom: 8.33333vw;
  width: 66.66667vw; }
  @media screen and (min-width: 87.5em) {
    .boilerplate__link {
      margin-bottom: 116.66667px; } }
  @media screen and (min-width: 87.5em) {
    .boilerplate__link {
      width: 933.33333px; } }
  @media screen and (min-width: 48em) {
    .boilerplate__link {
      margin: 0;
      width: 25vw;
      margin-right: 12.5vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .boilerplate__link {
      width: 350px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .boilerplate__link {
      margin-right: 175px; } }
  .boilerplate__link img:first-of-type {
    width: 100%; }

.boilerplate__link--small {
  width: 37.5vw;
  margin-right: 8.33333vw; }
  @media screen and (min-width: 87.5em) {
    .boilerplate__link--small {
      width: 525px; } }
  @media screen and (min-width: 87.5em) {
    .boilerplate__link--small {
      margin-right: 116.66667px; } }
  @media screen and (min-width: 48em) {
    .boilerplate__link--small {
      margin: 0;
      width: 12.5vw;
      margin-right: 2.08333vw;
      margin-top: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .boilerplate__link--small {
      width: 175px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .boilerplate__link--small {
      margin-right: 29.16667px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .boilerplate__link--small {
      margin-top: 58.33333px; } }
  .boilerplate__link--small img:first-of-type {
    background-color: #FFF; }

.boilerplate__link--small:last-child {
  margin: 0; }

.box {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 48em) {
    .box {
      flex-direction: row;
      flex-wrap: wrap; } }

.box--full {
  flex: 0 0 100%; }

.box--half {
  width: 100vw;
  flex: 0 0 100%; }
  @media screen and (min-width: 87.5em) {
    .box--half {
      width: 1400px; } }
  @media screen and (min-width: 48em) {
    .box--half {
      width: 50vw;
      flex: 0 0 50%;
      flex-direction: column; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .box--half {
      width: 700px; } }

.box--third {
  align-self: flex-start;
  width: 66.66667vw;
  margin-left: 8.33333vw;
  z-index: 1; }
  @media screen and (min-width: 87.5em) {
    .box--third {
      width: 933.33333px; } }
  @media screen and (min-width: 87.5em) {
    .box--third {
      margin-left: 116.66667px; } }
  @media screen and (min-width: 48em) {
    .box--third {
      align-self: center;
      width: 33.33333vw;
      margin-left: initial; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .box--third {
      width: 466.66667px; } }

.box--fourth {
  width: 50vw;
  align-self: flex-end;
  margin-right: 8.33333vw;
  z-index: 1; }
  @media screen and (min-width: 87.5em) {
    .box--fourth {
      width: 700px; } }
  @media screen and (min-width: 87.5em) {
    .box--fourth {
      margin-right: 116.66667px; } }
  @media screen and (min-width: 48em) {
    .box--fourth {
      width: 22.91667vw;
      margin-top: auto;
      margin-right: 0;
      align-self: center; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .box--fourth {
      width: 320.83333px; } }

.box--center-center {
  justify-content: center;
  align-items: center;
  align-content: center; }
  @media screen and (min-width: 48em) {
    .box--center-center {
      justify-content: center;
      align-items: center;
      align-content: center; } }

@media screen and (min-width: 48em) {
  .box--top-center {
    justify-content: center;
    align-items: flex-start; } }

@media screen and (min-width: 48em) {
  .box--bottom-center {
    justify-content: flex-end;
    align-items: center;
    align-content: center; } }

.box--absolute-center {
  position: relative; }
  @media screen and (min-width: 48em) {
    .box--absolute-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 25vw;
      position: absolute;
      text-align: center; } }

.box--translate-half-right {
  z-index: 1;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .box--translate-half-right {
      transform: translateX(50%);
      text-align: center; } }

.box--pull-down .box--third {
  align-self: flex-end;
  margin-right: 8.33333vw; }
  @media screen and (min-width: 87.5em) {
    .box--pull-down .box--third {
      margin-right: 116.66667px; } }
  @media screen and (min-width: 48em) {
    .box--pull-down .box--third {
      margin: 0;
      align-self: auto; } }

.box--start {
  margin-bottom: 16.66667vw; }
  @media screen and (min-width: 87.5em) {
    .box--start {
      margin-bottom: 233.33333px; } }

.box--grey {
  background-color: #f1f1f1;
  padding-bottom: 8.33333vw;
  position: relative; }
  @media screen and (min-width: 87.5em) {
    .box--grey {
      padding-bottom: 116.66667px; } }
  .box--grey::before {
    content: '';
    position: absolute;
    display: block;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 0; }
  @media screen and (min-width: 48em) {
    .box--grey {
      min-height: 80vh; }
      .box--grey::before {
        display: none; } }

.box--border {
  border: 1px solid #000;
  padding: 5px;
  position: relative; }
  .box--border img {
    display: block; }

@media screen and (min-width: 48em) {
  .box--border-right {
    border-right: 1px solid #000; } }

.footer {
  font-size: 10px;
  font-weight: 500;
  flex: 0 0 auto; }

.gallery {
  max-width: 83.33333vw; }
  @media screen and (min-width: 87.5em) {
    .gallery {
      max-width: 1166.66667px; } }
  @media screen and (min-width: 36em) {
    .gallery {
      max-width: 66.66667vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .gallery {
      max-width: 933.33333px; } }
  @media screen and (min-width: 64em) {
    .gallery {
      max-width: 50vw; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .gallery {
      max-width: 700px; } }
  @media screen and (min-width: 48em) {
    .gallery {
      max-width: 83.33333vw;
      border-collapse: collapse;
      display: table; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery {
      max-width: 1166.66667px; } }

.gallery--top .gallery__item {
  margin-bottom: 4.16667vw;
  vertical-align: bottom;
  order: 2; }
  @media screen and (min-width: 87.5em) {
    .gallery--top .gallery__item {
      margin-bottom: 58.33333px; } }
  .gallery--top .gallery__item.has-caption {
    order: 1; }
  @media screen and (min-width: 48em) {
    .gallery--top .gallery__item {
      margin: 0; } }

@media screen and (min-width: 48em) {
  .gallery--top .gallery__item:nth-child(1) {
    max-width: 25vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery--top .gallery__item:nth-child(1) {
      max-width: 350px; } }

@media screen and (min-width: 48em) {
  .gallery--top .gallery__item:nth-child(2) {
    max-width: 25vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery--top .gallery__item:nth-child(2) {
      max-width: 350px; } }

@media screen and (min-width: 48em) {
  .gallery--top .gallery__item:nth-child(3) {
    max-width: 31.25vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery--top .gallery__item:nth-child(3) {
      max-width: 437.5px; } }

@media screen and (min-width: 48em) {
  .gallery--bottom .gallery__item:nth-child(1) {
    max-width: 45.83333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery--bottom .gallery__item:nth-child(1) {
      max-width: 641.66667px; } }

@media screen and (min-width: 48em) {
  .gallery--bottom .gallery__item:nth-child(2) {
    max-width: 25vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery--bottom .gallery__item:nth-child(2) {
      max-width: 350px; } }

@media screen and (min-width: 48em) {
  .gallery--bottom .gallery__item:nth-child(3) {
    max-width: 25vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery--bottom .gallery__item:nth-child(3) {
      max-width: 350px; } }

.gallery--bottom .gallery__item {
  display: none; }
  @media screen and (min-width: 48em) {
    .gallery--bottom .gallery__item {
      vertical-align: top;
      display: table-cell; } }

@media screen and (min-width: 48em) {
  .gallery--bottom .gallery__item:only-child {
    max-width: 33.33333vw;
    display: block;
    margin: 0 auto; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .gallery--bottom .gallery__item:only-child {
      max-width: 466.66667px; } }

.gallery__item {
  position: relative; }
  .gallery__item img {
    display: block;
    width: 100%;
    height: auto; }
  @media screen and (min-width: 48em) {
    .gallery__item {
      display: table-cell;
      padding: 15px; } }

.gallery__caption {
  display: table;
  width: 100%; }
  .gallery__caption p {
    width: 100%; }

.gallery__caption-text {
  display: table-caption; }

.gallery__credits {
  position: absolute;
  padding: 0 2px;
  bottom: 17px;
  right: 17px;
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 10px; }
  .gallery__credits::before {
    content: '\00a9';
    display: inline-block;
    padding-right: 2px; }

.header {
  background-color: #f1f1f1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex: 0 0 100%;
  flex-flow: column nowrap; }

.header__image {
  margin-top: -1em;
  margin-bottom: .5em;
  max-height: 300px; }
  @media screen and (min-width: 36em) {
    .header__image {
      max-height: 400px; } }

.header--image {
  padding-top: 70px;
  padding-bottom: 50px; }
  @media screen and (min-width: 36em) {
    .header--image {
      padding-top: 96px; } }

.header--small {
  height: 70px;
  padding: 0; }
  .header--small h1 {
    margin-top: 55px; }
  @media screen and (min-width: 48em) {
    .header--small {
      min-height: 96px; }
      .header--small h1 {
        margin-top: 70px; } }

.multicolumn {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  max-width: 83.33333vw; }
  @media screen and (min-width: 87.5em) {
    .multicolumn {
      max-width: 1166.66667px; } }
  @media screen and (min-width: 48em) {
    .multicolumn {
      flex-direction: row; } }

.multicolumn__col {
  display: flex; }

@media screen and (min-width: 48em) {
  .multicolumn__col--1of2:first-of-type {
    flex: 1 1 50%;
    padding-right: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .multicolumn__col--1of2:first-of-type {
      padding-right: 58.33333px; } }

@media screen and (min-width: 48em) {
  .multicolumn__col--1of2 {
    flex: 1 1 50%;
    padding-left: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .multicolumn__col--1of2 {
      padding-left: 58.33333px; } }

@media screen and (min-width: 48em) {
  .multicolumn__col--1of3:first-of-type {
    flex: 1 1 40%; } }

@media screen and (min-width: 48em) {
  .multicolumn__col--1of3 {
    flex: 1 1 30%; } }

@media screen and (min-width: 48em) {
  .multicolumn__headline {
    flex: 1 1 100%;
    padding-left: 4.16667vw;
    padding-right: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .multicolumn__headline {
      padding-left: 58.33333px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .multicolumn__headline {
      padding-right: 58.33333px; } }

@media screen and (min-width: 48em) {
  .multicolumn__headline--span {
    max-width: 66.66667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .multicolumn__headline--span {
      max-width: 933.33333px; } }

@media screen and (min-width: 48em) {
  .multicolumn__col--1of2 .multicolumn__headline {
    padding: 0; } }

.multicolumn__list {
  flex: 1; }

.multicolumn__list-item {
  line-height: 1.6;
  margin-bottom: 25px;
  width: 100%; }
  .multicolumn__list-item:last-child {
    margin-bottom: 0; }
  .multicolumn__list-item p {
    font-weight: 400;
    display: inline-block; }
  .multicolumn__list-item p:last-of-type {
    margin-bottom: 5px; }
  .multicolumn__list-item > a {
    display: block; }

.multicolumn__list-item--single {
  margin-bottom: 10px; }

.multicolumn__list-item-headline {
  margin-bottom: 5px;
  display: flex !important; }
  .multicolumn__list-item-headline::before {
    content: '';
    display: inline-block;
    height: 1px;
    background: #000;
    vertical-align: middle;
    margin-right: .5rem;
    flex: 0 0 1rem;
    margin-top: 12px; }
    @media screen and (min-width: 48em) {
      .multicolumn__list-item-headline::before {
        flex: 0 0 2rem; } }

.multicolumn__list-item--single .multicolumn__list-item-headline {
  margin-bottom: 0; }

/* NAVIGATION */
.nav {
  display: flex;
  padding-left: 4.16667vw;
  padding-right: 4.16667vw;
  align-items: center;
  z-index: 999;
  position: absolute;
  width: 100vw;
  height: 70px;
  flex: 0 0 auto; }
  @media screen and (min-width: 87.5em) {
    .nav {
      padding-left: 58.33333px; } }
  @media screen and (min-width: 87.5em) {
    .nav {
      padding-right: 58.33333px; } }
  @media screen and (min-width: 36em) {
    .nav {
      height: 96px; } }
  .nav.is-sticky {
    position: fixed;
    background-color: #f1f1f1; }

.nav__menu {
  margin-left: auto; }

.nav__language-switcher {
  display: inline-block;
  margin-right: 2.08333vw;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  transition: opacity .15s ease;
  opacity: 1;
  text-decoration: none; }
  @media screen and (min-width: 87.5em) {
    .nav__language-switcher {
      margin-right: 29.16667px; } }
  .nav__language-switcher.is-hidden {
    opacity: 0; }

.nav__home {
  display: flex;
  align-items: center;
  text-decoration: none; }
  .nav__home span {
    display: inline-block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    margin-left: 15px; }

.nav__logo {
  width: 25px;
  display: block;
  opacity: 1;
  transition: opacity 1s ease; }
  @media screen and (min-width: 36em) {
    .nav__logo {
      width: 35px; } }
  .nav__logo.is-hidden {
    opacity: 0; }

.nav__hamburger {
  position: fixed;
  color: #FFF;
  display: block;
  max-height: 100vh;
  width: 90vw;
  transform: translateX(100%);
  top: 0;
  right: 0;
  transition: all .25s ease;
  z-index: 2;
  overflow-y: auto;
  visibility: hidden;
  will-change: transform; }
  @media screen and (min-width: 36em) {
    .nav__hamburger {
      max-height: 100vh;
      width: 50vw; } }
  @media screen and (min-width: 64em) {
    .nav__hamburger {
      width: 33.333vw; } }
  .nav__hamburger.is-active {
    transform: translateX(0);
    visibility: visible; }

.nav__hamburger-content {
  padding: 8.33333vw;
  padding-bottom: 16.66667vw;
  background-color: #000; }
  @media screen and (min-width: 87.5em) {
    .nav__hamburger-content {
      padding: 116.66667px; } }
  @media screen and (min-width: 87.5em) {
    .nav__hamburger-content {
      padding-bottom: 233.33333px; } }
  @media screen and (min-width: 48em) {
    .nav__hamburger-content {
      padding: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .nav__hamburger-content {
      padding: 58.33333px; } }

.nav__hamburger-header {
  background-color: transparent;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 4.16667vw;
  height: 70px; }
  @media screen and (min-width: 87.5em) {
    .nav__hamburger-header {
      padding-right: 58.33333px; } }
  @media screen and (min-width: 36em) {
    .nav__hamburger-header {
      height: 96px; } }
  @media screen and (min-width: 48em) {
    .nav__hamburger-header {
      padding-right: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .nav__hamburger-header {
      padding-right: 58.33333px; } }

.nav__hamburger-button {
  display: inline-block;
  opacity: 1;
  transition: opacity .15s ease;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer; }
  .nav__hamburger-button:hover {
    text-decoration: line-through; }
  .nav__hamburger-button.is-hidden {
    opacity: 0; }

.nav__hamburger-button--close {
  color: #000; }

.nav__hamburger-others-link {
  color: #000;
  margin-top: -50px; }

.nav__hamburger-item-list {
  margin-bottom: 30px; }

.nav__hamburger-item {
  font-size: 10px;
  font-weight: 500;
  line-height: 2;
  display: block;
  text-decoration: none; }

.nav__hamburger-headline {
  font-size: 22px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 15px;
  display: inline-block;
  text-decoration: none; }
  .nav__hamburger-headline:first-child {
    margin-bottom: 30px;
    display: block; }

.nav__hamburger-kontakt {
  text-align: center;
  align-self: flex-start;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #fff;
  line-height: 1.8;
  padding-top: 8.33333vw;
  padding-bottom: 8.33333vw; }
  @media screen and (min-width: 87.5em) {
    .nav__hamburger-kontakt {
      padding-top: 116.66667px; } }
  @media screen and (min-width: 87.5em) {
    .nav__hamburger-kontakt {
      padding-bottom: 116.66667px; } }
  @media screen and (min-width: 48em) {
    .nav__hamburger-kontakt {
      padding-top: 4.16667vw;
      padding-bottom: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .nav__hamburger-kontakt {
      padding-top: 58.33333px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .nav__hamburger-kontakt {
      padding-bottom: 58.33333px; } }

.nav__hamburger-kontakt-maps-link,
.nav__hamburger-kontakt-email {
  display: inline-block;
  text-decoration: none; }

.nav__hamburger-kontakt-phone-mobile {
  text-decoration: none; }
  @media screen and (min-width: 48em) {
    .nav__hamburger-kontakt-phone-mobile {
      display: none; } }

.nav__hamburger-kontakt-phone-desktop {
  display: none; }
  @media screen and (min-width: 48em) {
    .nav__hamburger-kontakt-phone-desktop {
      text-decoration: none;
      display: block; } }

.nav__footer {
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; }

.nav__footer-item {
  font-size: 10px;
  text-decoration: none; }

.nav__footer-item:only-child {
  margin-left: auto; }

.nav__footer-item--back img {
  transform: rotate(-180deg); }

.nav__latest-news-image {
  margin-top: 20px; }

.nav__latest-news-image img {
  width: 100%; }

.nav__latest-news-title {
  font-size: initial; }

.pagination {
  display: flex;
  flex: 0 0 100%;
  background-color: #000;
  justify-content: center;
  padding: 6.25vw; }
  @media screen and (min-width: 87.5em) {
    .pagination {
      padding: 87.5px; } }
  @media screen and (min-width: 48em) {
    .pagination {
      padding: 2.08333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .pagination {
      padding: 29.16667px; } }

.pagination__link {
  width: 8.33333vw;
  height: 8.33333vw;
  border: 1px solid #FFF;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  transition: all .25s ease-in-out;
  text-decoration: none;
  margin-left: 0.83333vw;
  margin-right: 0.83333vw; }
  @media screen and (min-width: 87.5em) {
    .pagination__link {
      width: 116.66667px; } }
  @media screen and (min-width: 87.5em) {
    .pagination__link {
      height: 116.66667px; } }
  @media screen and (min-width: 87.5em) {
    .pagination__link {
      margin-left: 11.66667px; } }
  @media screen and (min-width: 87.5em) {
    .pagination__link {
      margin-right: 11.66667px; } }
  @media screen and (min-width: 48em) {
    .pagination__link {
      width: 40px;
      height: 40px; } }

.pagination__link:hover,
.pagination__link--active {
  background-color: #FFF;
  color: #000; }

.pagination__link--dummy {
  pointer-events: none;
  border-color: #000; }

.pagination__link--prev,
.pagination__link--next {
  transform: rotate(0deg); }
  .pagination__link--prev img,
  .pagination__link--next img {
    transition: opacity .25s ease-in-out;
    max-width: 60%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    .pagination__link--prev img:nth-child(2),
    .pagination__link--next img:nth-child(2) {
      opacity: 0;
      visibility: hidden; }
  .pagination__link--prev:hover img:nth-child(2),
  .pagination__link--next:hover img:nth-child(2) {
    opacity: 1;
    visibility: visible; }

.pagination__link--prev {
  transform: rotate(-180deg); }

.project__header {
  flex-wrap: wrap;
  background-color: #f1f1f1;
  flex-direction: column;
  justify-content: flex-start; }

.project__header-image {
  text-align: center;
  margin-top: -.9em;
  width: 50vw; }
  @media screen and (min-width: 87.5em) {
    .project__header-image {
      width: 700px; } }
  @media screen and (min-width: 64em) {
    .project__header-image {
      width: 25vw; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .project__header-image {
      width: 350px; } }

.project__header-title {
  font-size: 32px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.1;
  z-index: 1; }
  @media screen and (min-width: 64em) {
    .project__header-title {
      font-size: 38px; } }

.project__header-year,
.project__header-subtitle {
  text-align: center;
  line-height: 1.8;
  font-size: 10px;
  padding: 10px 0;
  font-weight: 500; }

.project__icons-wrapper {
  overflow-x: auto; }

.project__icons {
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }
  @media screen and (min-width: 87.5em) {
    .project__icons {
      max-width: 1400px; } }

.project__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  flex: 0 1 100px;
  min-width: 25vw;
  max-width: 33.33333vw; }
  @media screen and (min-width: 87.5em) {
    .project__icon {
      min-width: 350px; } }
  @media screen and (min-width: 87.5em) {
    .project__icon {
      max-width: 466.66667px; } }
  @media screen and (max-width: 36em) {
    .project__icon {
      font-size: 12px;
      min-width: 41.66667vw; } }
  @media screen and (max-width: 36em) and (min-width: 87.5em) {
    .project__icon {
      min-width: 583.33333px; } }
  @media screen and (min-width: 36em) {
    .project__icon {
      min-width: 25vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .project__icon {
      min-width: 350px; } }
  @media screen and (min-width: 48em) {
    .project__icon {
      min-width: 16.66667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .project__icon {
      min-width: 233.33333px; } }
  @media screen and (min-width: 80em) {
    .project__icon {
      min-width: 12.5vw; } }
  @media screen and (min-width: 80em) and (min-width: 87.5em) {
    .project__icon {
      min-width: 175px; } }

.project__icon-title {
  font-size: 10px;
  padding: 10px 0;
  font-weight: 500; }
  @media screen and (max-width: 36em) {
    .project__icon-title {
      font-size: 8px; } }

.project__description-headline {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 6.25vw;
  max-width: 100vw; }
  @media screen and (min-width: 87.5em) {
    .project__description-headline {
      padding-bottom: 87.5px; } }
  @media screen and (min-width: 87.5em) {
    .project__description-headline {
      max-width: 1400px; } }
  @media screen and (min-width: 36em) {
    .project__description-headline {
      padding-left: 12.5vw;
      padding-right: 12.5vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .project__description-headline {
      padding-left: 175px; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .project__description-headline {
      padding-right: 175px; } }
  @media screen and (min-width: 64em) {
    .project__description-headline {
      padding-left: 25vw;
      padding-right: 25vw;
      margin: 0; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .project__description-headline {
      padding-left: 350px; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .project__description-headline {
      padding-right: 350px; } }

.project__type {
  display: block; }
  .project__type::before, .project__type::after {
    content: '—';
    display: inline-block;
    padding: 1em; }

.projects__item {
  display: block;
  width: 66.66667vw;
  margin-bottom: 8.33333vw;
  padding-right: 2.08333vw;
  padding-left: 2.08333vw; }
  @media screen and (min-width: 87.5em) {
    .projects__item {
      width: 933.33333px; } }
  @media screen and (min-width: 87.5em) {
    .projects__item {
      margin-bottom: 116.66667px; } }
  @media screen and (min-width: 87.5em) {
    .projects__item {
      padding-right: 29.16667px; } }
  @media screen and (min-width: 87.5em) {
    .projects__item {
      padding-left: 29.16667px; } }
  .projects__item:nth-child(odd) {
    margin-left: -20.83333vw; }
    @media screen and (min-width: 87.5em) {
      .projects__item:nth-child(odd) {
        margin-left: -291.66667px; } }
  .projects__item:nth-child(even) {
    margin-right: -20.83333vw; }
    @media screen and (min-width: 87.5em) {
      .projects__item:nth-child(even) {
        margin-right: -291.66667px; } }
  @media screen and (min-width: 36em) {
    .projects__item {
      width: 41.66667vw;
      margin-top: 8.33333vw; }
      .projects__item:nth-child(odd), .projects__item:nth-child(even) {
        margin: 0; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .projects__item {
      width: 583.33333px; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .projects__item {
      margin-top: 116.66667px; } }
  @media screen and (min-width: 36em) {
      .projects__item:nth-child(2n+2) {
        width: 41.66667vw;
        padding-right: 6.25vw;
        padding-left: 6.25vw;
        margin: 0;
        margin-top: 12.5vw; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(2n+2) {
        width: 583.33333px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(2n+2) {
        padding-right: 87.5px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(2n+2) {
        padding-left: 87.5px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(2n+2) {
        margin-top: 175px; } }
  @media screen and (min-width: 36em) {
      .projects__item:nth-child(4n-1) {
        width: 41.66667vw;
        padding-right: 6.25vw;
        padding-left: 6.25vw;
        margin-top: 0vw; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n-1) {
        width: 583.33333px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n-1) {
        padding-right: 87.5px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n-1) {
        padding-left: 87.5px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n-1) {
        margin-top: 0px; } }
  @media screen and (min-width: 36em) {
      .projects__item:nth-child(4n+4) {
        width: 41.66667vw;
        padding: 0;
        padding-right: 2.08333vw;
        padding-left: 2.08333vw;
        margin-bottom: 4.16667vw; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n+4) {
        width: 583.33333px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n+4) {
        padding-right: 29.16667px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n+4) {
        padding-left: 29.16667px; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:nth-child(4n+4) {
        margin-bottom: 58.33333px; } }
  @media screen and (min-width: 36em) {
      .projects__item:first-of-type {
        margin: 0; }
      .projects__item:last-child:nth-child(odd):not(:only-of-type) {
        margin-right: auto;
        margin-left: 4.16667vw; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item:last-child:nth-child(odd):not(:only-of-type) {
        margin-left: 58.33333px; } }
  @media screen and (min-width: 36em) {
      .projects__item--border {
        margin-left: 41.66667vw; } }
    @media screen and (min-width: 36em) and (min-width: 87.5em) {
      .projects__item--border {
        margin-left: 583.33333px; } }
  @media screen and (min-width: 36em) {
      .projects__item--border:nth-child(3) {
        text-align: right; }
        .projects__item--border:nth-child(3) a {
          display: inline-block; } }
  .projects__item:only-of-type {
    margin: 0; }

.projects__item--border {
  margin: 0 auto !important;
  margin-bottom: 70px !important;
  text-align: left !important; }

.projects__item-image {
  width: 100%; }

.projects__item-image--border {
  border: 2px solid #000;
  max-width: 33.33333vw; }
  @media screen and (min-width: 87.5em) {
    .projects__item-image--border {
      max-width: 466.66667px; } }

.projects__caption {
  display: table;
  width: 100%; }

.projects__caption-text {
  text-align: center;
  margin-left: -4.16667vw;
  margin-right: -4.16667vw; }
  @media screen and (min-width: 87.5em) {
    .projects__caption-text {
      margin-left: -58.33333px; } }
  @media screen and (min-width: 87.5em) {
    .projects__caption-text {
      margin-right: -58.33333px; } }

.projects__category-bar {
  height: 45px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998; }

.projects__category-bar-container {
  display: flex;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #000;
  color: #FFF;
  overflow-x: auto; }
  @media screen and (min-width: 36em) {
    .projects__category-bar-container {
      overflow: hidden;
      justify-content: center; } }

.projects__category-bar-item {
  padding: 0 20px;
  font-size: 10px;
  text-decoration: none; }

.projects__category-bar-item--active {
  font-weight: 500; }

.projects__category-bar-item-divider {
  display: flex;
  height: 100%;
  align-items: center; }
  .projects__category-bar-item-divider::before {
    content: '';
    display: inline-block;
    height: 1px;
    background: #FFF;
    vertical-align: middle;
    margin: 0 .5rem;
    width: 1rem; }
    @media screen and (min-width: 48em) {
      .projects__category-bar-item-divider::before {
        width: 2rem; } }

.projects__tags {
  text-align: right; }
  .projects__tags span::after {
    content: ' — '; }
  .projects__tags span:last-of-type::after {
    content: ''; }

.projects__title {
  font-size: 16px;
  max-width: 85%; }
  @media screen and (min-width: 48em) {
    .projects__title {
      font-size: 22px;
      max-width: 80%; } }

.projects__table {
  width: 100%;
  margin-bottom: 4rem; }

.projects__list-item:not(.projects__list-item--leading) {
  border-bottom: 1px solid #eee; }

.projects__list-item--leading {
  border-collapse: collapse;
  font-style: italic;
  margin-bottom: 25px;
  font-weight: 500; }
  .projects__list-item--leading > div {
    padding-right: 25px;
    position: relative; }
    .projects__list-item--leading > div span {
      display: inline-block;
      padding-bottom: 5px; }
    .projects__list-item--leading > div:last-child {
      padding-right: 0; }
    .projects__list-item--leading > div::after {
      content: '';
      display: block;
      background-color: #000;
      height: 1px; }
  .projects__list-item--leading th {
    border-bottom: 1px solid #000;
    text-align: left;
    cursor: pointer; }

.projects__list-link {
  border-collapse: collapse;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer; }
  .projects__list-link img {
    display: none; }
  .projects__list-link:hover {
    color: #000;
    background-color: #f1f1f1; }
    .projects__list-link:hover img {
      display: inline;
      margin-left: 5px; }
  .projects__list-link td {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee; }

.projects__item-title {
  padding-right: 5px;
  padding-left: 5px; }

.projects__item-city {
  padding: 5px; }

.projects__item-country {
  padding: 5px; }

.projects__item-year {
  padding: 5px; }

.projects__item-category {
  padding: 5px;
  text-transform: capitalize; }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table,
  .projects__table thead,
  .projects__table tbody,
  .projects__table th,
  .projects__table td,
  .projects__table tr {
    display: block; } }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; } }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table td {
    position: relative;
    padding-left: 50%; }
    .projects__table td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap; } }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table td:nth-of-type(1):before {
    content: "Projekt";
    font-weight: 500;
    padding-top: 7px; } }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table td:nth-of-type(2):before {
    content: "Stadt";
    font-weight: 500;
    padding-top: 7px; } }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table td:nth-of-type(3):before {
    content: "Land";
    font-weight: 500;
    padding-top: 7px; } }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table td:nth-of-type(4):before {
    content: "Jahr";
    font-weight: 500;
    padding-top: 7px; } }

@media screen and (min-width: 20em) and (max-width: 64em) {
  .projects__table td:nth-of-type(5):before {
    content: "Typ";
    font-weight: 500;
    padding-top: 7px; } }

section {
  flex: 0 0 100vw; }
  section:first-of-type {
    padding-top: 6.25vw; }
    @media screen and (min-width: 87.5em) {
      section:first-of-type {
        padding-top: 87.5px; } }

.section {
  padding: 6.25vw;
  margin: 0 auto;
  max-width: 100vw; }
  @media screen and (min-width: 87.5em) {
    .section {
      padding: 87.5px; } }
  @media screen and (min-width: 87.5em) {
    .section {
      max-width: 1400px; } }
  @media screen and (min-width: 48em) {
    .section {
      padding: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .section {
      padding: 58.33333px; } }
  @media screen and (min-width: 48em) {
      .section:last-of-type:not('.section--dark') {
        padding-bottom: 8.33333vw; } }
    @media screen and (min-width: 48em) and (min-width: 87.5em) {
      .section:last-of-type:not('.section--dark') {
        padding-bottom: 116.66667px; } }

@media screen and (max-width: 48em) {
  .section:nth-of-type(even) .textbox .textbox__image {
    align-self: flex-end; } }

.section--half {
  margin: 0;
  flex: 0 0 100%; }
  @media screen and (min-width: 48em) {
    .section--half {
      display: flex;
      flex: 0 0 50%; } }

@media screen and (min-width: 48em) {
  .section--half .projects__item:only-of-type {
    width: 33.33333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .section--half .projects__item:only-of-type {
      width: 466.66667px; } }

.section--collapse {
  padding: 0; }

.section--collapse-bottom {
  padding-bottom: 0; }

.section--dark {
  background-color: #000;
  color: #FFF;
  padding-top: 8.33333vw;
  padding-bottom: 8.33333vw; }
  @media screen and (min-width: 87.5em) {
    .section--dark {
      padding-top: 116.66667px; } }
  @media screen and (min-width: 87.5em) {
    .section--dark {
      padding-bottom: 116.66667px; } }
  @media screen and (min-width: 48em) {
    .section--dark {
      max-width: 100vw;
      padding-top: 4.16667vw;
      padding-bottom: 4.16667vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .section--dark {
      padding-top: 58.33333px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .section--dark {
      padding-bottom: 58.33333px; } }

.section--grey {
  padding-top: 6.25vw;
  background-color: #f8f7f7; }
  @media screen and (min-width: 87.5em) {
    .section--grey {
      padding-top: 87.5px; } }
  @media screen and (min-width: 48em) {
    .section--grey {
      max-width: 100vw; } }

.section--projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1285px; }
  .section--projects:first-of-type {
    padding-bottom: 45px; }
    @media screen and (min-width: 48em) {
      .section--projects:first-of-type {
        padding-bottom: 90px; } }

.section--icons {
  padding-top: 0;
  padding-bottom: 0; }

.section--no-pb {
  padding-bottom: 0 !important; }

.slider {
  margin: 0 auto;
  max-width: 83.33333vw; }
  @media screen and (min-width: 87.5em) {
    .slider {
      max-width: 1166.66667px; } }
  @media screen and (min-width: 36em) {
    .slider {
      max-width: 66.66667vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .slider {
      max-width: 933.33333px; } }

.slider__item > a img {
  width: 100%; }

.slider__headline {
  font-weight: 400;
  line-height: 1.1;
  font-size: 32px;
  position: relative;
  margin-bottom: -13px;
  z-index: 1; }

.slider__description {
  text-align: center;
  font-weight: 300;
  line-height: 1.6;
  padding-top: 4.16667vw;
  padding-left: 4.16667vw;
  padding-right: 4.16667vw; }
  @media screen and (min-width: 87.5em) {
    .slider__description {
      padding-top: 58.33333px; } }
  @media screen and (min-width: 87.5em) {
    .slider__description {
      padding-left: 58.33333px; } }
  @media screen and (min-width: 87.5em) {
    .slider__description {
      padding-right: 58.33333px; } }

.slider--news {
  margin: 0 25px; }
  @media screen and (min-width: 48em) {
    .slider--news {
      margin: 0 50px; } }

.slider__link {
  display: block; }

.start__intro {
  display: inline-block;
  max-width: 83.33333vw;
  padding-top: 80px; }
  @media screen and (min-width: 87.5em) {
    .start__intro {
      max-width: 1166.66667px; } }
  @media screen and (min-width: 36em) {
    .start__intro {
      padding-top: 100px; } }
  @media screen and (min-width: 48em) {
    .start__intro {
      max-width: 50vw;
      padding-top: 130px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .start__intro {
      max-width: 700px; } }

.start__intro-copy {
  font-size: 22px;
  font-weight: normal;
  line-height: 1.4;
  margin-bottom: 6.25vw; }
  @media screen and (min-width: 87.5em) {
    .start__intro-copy {
      margin-bottom: 87.5px; } }
  @media screen and (min-width: 48em) {
    .start__intro-copy {
      font-size: 30px;
      margin-bottom: 0; } }

.start__image {
  width: 100%;
  margin-bottom: 5px; }

.start__name {
  margin-left: .8em;
  margin-bottom: 10.41667vw; }
  @media screen and (min-width: 87.5em) {
    .start__name {
      margin-bottom: 145.83333px; } }
  @media screen and (min-width: 48em) {
    .start__name {
      margin-bottom: 2.08333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .start__name {
      margin-bottom: 29.16667px; } }

.studio__news {
  width: 41.66667vw; }
  @media screen and (min-width: 87.5em) {
    .studio__news {
      width: 583.33333px; } }

.team__headline {
  text-align: center;
  width: 100%; }
  @media screen and (min-width: 64em) {
    .team__headline {
      flex: 0 0 100%;
      text-align: center; } }

.team__member-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  max-width: 91.66667vw;
  overflow-x: scroll;
  padding-bottom: 20px; }
  @media screen and (min-width: 87.5em) {
    .team__member-container {
      max-width: 1283.33333px; } }
  @media screen and (min-width: 64em) {
    .team__member-container {
      flex-wrap: wrap;
      justify-content: center;
      width: 83.33333vw;
      padding: 0;
      overflow: auto; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .team__member-container {
      width: 1166.66667px; } }

.team__member {
  min-width: 35.41667vw;
  padding-left: 2.08333vw;
  padding-right: 2.08333vw; }
  @media screen and (min-width: 87.5em) {
    .team__member {
      min-width: 495.83333px; } }
  @media screen and (min-width: 87.5em) {
    .team__member {
      padding-left: 29.16667px; } }
  @media screen and (min-width: 87.5em) {
    .team__member {
      padding-right: 29.16667px; } }
  @media screen and (min-width: 36em) {
    .team__member {
      min-width: 20.83333vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .team__member {
      min-width: 291.66667px; } }
  @media screen and (min-width: 64em) {
    .team__member {
      min-width: auto;
      width: 14.58333vw;
      padding-left: 2.08333vw;
      padding-right: 2.08333vw;
      padding-top: 0;
      padding-bottom: 25px; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .team__member {
      width: 204.16667px; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .team__member {
      padding-left: 29.16667px; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .team__member {
      padding-right: 29.16667px; } }

.team__member-image {
  width: 100%;
  margin-bottom: 5px; }

.team__boilerplate {
  width: 33.33333vw; }
  @media screen and (min-width: 87.5em) {
    .team__boilerplate {
      width: 466.66667px; } }

.team__member-name {
  line-height: 1.2;
  font-size: 10px; }

@media screen and (min-width: 48em) {
  .anchor-nav ul {
    text-align: center; } }

.anchor-nav li {
  margin-bottom: 10px;
  font-weight: 400; }
  @media screen and (min-width: 48em) {
    .anchor-nav li {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px; } }

.textbox {
  display: flex;
  align-items: flex-start;
  flex-direction: column; }
  @media screen and (min-width: 48em) {
    .textbox {
      flex-direction: row;
      flex-wrap: wrap; } }
  .textbox p {
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 25.6px; }

.textbox__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.textbox__headline {
  font-weight: 400;
  line-height: 1.4;
  font-size: 22px; }
  @media screen and (min-width: 48em) {
    .textbox__headline {
      font-size: 30px; } }

.textbox__headline--small {
  font-size: 19px; }
  @media screen and (min-width: 48em) {
    .textbox__headline--small {
      font-size: 22px; } }

.textbox--leading p:first-of-type::before {
  content: '';
  display: inline-block;
  height: 1px;
  background: #000;
  vertical-align: middle;
  margin-right: .5rem;
  width: 1rem; }
  @media screen and (min-width: 48em) {
    .textbox--leading p:first-of-type::before {
      width: 2rem; } }

@media screen and (min-width: 48em) {
  .textbox--4of12 {
    width: 33.33333vw;
    padding-left: 2.08333vw;
    padding-right: 2.08333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox--4of12 {
      width: 466.66667px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox--4of12 {
      padding-left: 29.16667px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox--4of12 {
      padding-right: 29.16667px; } }

.textbox--5of12 {
  width: 100%; }
  @media screen and (min-width: 48em) {
    .textbox--5of12 {
      width: 41.66667vw;
      padding-left: 2.08333vw;
      padding-right: 2.08333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox--5of12 {
      width: 583.33333px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox--5of12 {
      padding-left: 29.16667px; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox--5of12 {
      padding-right: 29.16667px; } }

.textbox__image {
  max-width: 100%;
  text-align: right; }
  .textbox__image img {
    width: 100%; }

.textbox__image .textbox__portrait-image {
  max-width: 58.33333vw; }
  @media screen and (min-width: 87.5em) {
    .textbox__image .textbox__portrait-image {
      max-width: 816.66667px; } }
  @media screen and (min-width: 48em) {
    .textbox__image .textbox__portrait-image {
      max-width: 20.83333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox__image .textbox__portrait-image {
      max-width: 291.66667px; } }

.textbox__image .textbox__landscape-image {
  max-width: 75vw; }
  @media screen and (min-width: 87.5em) {
    .textbox__image .textbox__landscape-image {
      max-width: 1050px; } }
  @media screen and (min-width: 48em) {
    .textbox__image .textbox__landscape-image {
      max-width: 33.33333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .textbox__image .textbox__landscape-image {
      max-width: 466.66667px; } }

.textbox__image ~ .has-image {
  margin-top: 8.33333vw; }
  @media screen and (min-width: 87.5em) {
    .textbox__image ~ .has-image {
      margin-top: 116.66667px; } }

.textbox__image--right {
  text-align: left; }
  @media screen and (min-width: 48em) {
    .textbox__image--right {
      order: 1; } }

.people .textbox__headline--small {
  padding-top: 250px;
  margin-top: -250px; }

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="button"]:focus,
  button:focus {
    outline: none; }

.headline__2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: -12px;
  z-index: 1;
  position: relative;
  line-height: 1; }
  @media screen and (min-width: 48em) {
    .headline__2 {
      font-size: 38px;
      margin-bottom: -15px; } }

.headline__sub-s {
  line-height: 1.8;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase; }

.headline--slanted {
  font-size: 22px;
  font-weight: 400;
  font-style: italic; }
  @media screen and (min-width: 48em) {
    .headline--slanted {
      font-size: 38px; } }

.link--arrow {
  display: block;
  backface-visibility: hidden;
  text-decoration: none; }
  .link--arrow img {
    transform: translateX(0);
    backface-visibility: hidden;
    transition: transform .25s ease; }
  .link--arrow:hover img {
    transform: translateX(2px); }

.link__arrow-image {
  width: 20px;
  vertical-align: middle; }

.mb--xs {
  margin-bottom: 1.04167vw; }
  @media screen and (min-width: 87.5em) {
    .mb--xs {
      margin-bottom: 14.58333px; } }

.mb--s {
  margin-bottom: 2.08333vw; }
  @media screen and (min-width: 87.5em) {
    .mb--s {
      margin-bottom: 29.16667px; } }

.mb--m {
  margin-bottom: 4.16667vw; }
  @media screen and (min-width: 87.5em) {
    .mb--m {
      margin-bottom: 58.33333px; } }

.mb--l {
  margin-bottom: 8.33333vw; }
  @media screen and (min-width: 87.5em) {
    .mb--l {
      margin-bottom: 116.66667px; } }

.mb--xl {
  margin-bottom: 16.66667vw; }
  @media screen and (min-width: 87.5em) {
    .mb--xl {
      margin-bottom: 233.33333px; } }

.piechart {
  width: 130px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #000;
  padding: 5px;
  display: inline-block; }
  .piechart::before {
    content: "";
    display: block;
    padding-top: 100%; }
  @media screen and (min-width: 48em) {
    .piechart {
      width: 8.33333vw; } }
  @media screen and (min-width: 48em) and (min-width: 87.5em) {
    .piechart {
      width: 116.66667px; } }

.piechart__description:nth-child(1)::before {
  background-color: #f5f5f5; }

svg .piechart__slice:nth-of-type(1) {
  fill: #f5f5f5; }

.piechart--1 .piechart__slice:nth-of-type(n+2) {
  display: none; }

.piechart__description:nth-child(2)::before {
  background-color: #dedede; }

svg .piechart__slice:nth-of-type(2) {
  fill: #dedede; }

.piechart--2 .piechart__slice:nth-of-type(n+3) {
  display: none; }

.piechart__description:nth-child(3)::before {
  background-color: #c0c0c0; }

svg .piechart__slice:nth-of-type(3) {
  fill: #c0c0c0; }

.piechart--3 .piechart__slice:nth-of-type(n+4) {
  display: none; }

.piechart__description:nth-child(4)::before {
  background-color: #9c9c9c; }

svg .piechart__slice:nth-of-type(4) {
  fill: #9c9c9c; }

.piechart--4 .piechart__slice:nth-of-type(n+5) {
  display: none; }

.piechart__description:nth-child(5)::before {
  background-color: #818181; }

svg .piechart__slice:nth-of-type(5) {
  fill: #818181; }

.piechart--5 .piechart__slice:nth-of-type(n+6) {
  display: none; }

.piechart__description:nth-child(6)::before {
  background-color: #585858; }

svg .piechart__slice:nth-of-type(6) {
  fill: #585858; }

.piechart--6 .piechart__slice:nth-of-type(n+7) {
  display: none; }

.piechart__description:nth-child(7)::before {
  background-color: #383838; }

svg .piechart__slice:nth-of-type(7) {
  fill: #383838; }

.piechart--7 .piechart__slice:nth-of-type(n+8) {
  display: none; }

.piechart__description:nth-child(8)::before {
  background-color: #232323; }

svg .piechart__slice:nth-of-type(8) {
  fill: #232323; }

.piechart--8 .piechart__slice:nth-of-type(n+9) {
  display: none; }

.piechart__container {
  width: 100%;
  text-align: center; }

.piechart__headline {
  width: 100%;
  text-align: center; }

.piechart__description-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  max-width: 91.66667vw;
  overflow-x: scroll; }
  @media screen and (min-width: 87.5em) {
    .piechart__description-container {
      max-width: 1283.33333px; } }
  @media screen and (min-width: 36em) {
    .piechart__description-container {
      max-width: 100vw;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      overflow-x: auto; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .piechart__description-container {
      max-width: 1400px; } }

.piechart__description {
  text-align: center;
  padding: 20px; }
  .piechart__description::before {
    display: inline-block;
    content: '';
    width: 15px;
    height: 15px; }

.piechart__legend {
  font-size: 12px; }

.piechart__legend--small {
  font-size: 8px;
  line-height: 1.8;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .15rem; }

.video {
  width: 83.33333vw; }
  @media screen and (min-width: 87.5em) {
    .video {
      width: 1166.66667px; } }
  @media screen and (min-width: 36em) {
    .video {
      width: 66.66667vw; } }
  @media screen and (min-width: 36em) and (min-width: 87.5em) {
    .video {
      width: 933.33333px; } }
  @media screen and (min-width: 64em) {
    .video {
      width: 50vw; } }
  @media screen and (min-width: 64em) and (min-width: 87.5em) {
    .video {
      width: 700px; } }

.video__container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }

.video__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.video__backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1; }

.video__button {
  backface-visibility: hidden;
  border-radius: 50%;
  background-color: #000;
  color: #FFF;
  font-size: 10px;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  transition: all .25s ease-in-out;
  z-index: 1;
  cursor: pointer; }
  .video__button:hover {
    box-shadow: 0 0 0 2px #fff;
    transform: translate(-50%, -52%); }

.video__description {
  font-weight: 300;
  line-height: 1.6;
  padding-top: 4.16667vw;
  padding-left: 4.16667vw;
  padding-right: 4.16667vw; }
  @media screen and (min-width: 87.5em) {
    .video__description {
      padding-top: 58.33333px; } }
  @media screen and (min-width: 87.5em) {
    .video__description {
      padding-left: 58.33333px; } }
  @media screen and (min-width: 87.5em) {
    .video__description {
      padding-right: 58.33333px; } }

.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }

.slick-prev:hover, .slick-prev:focus {
  outline: none;
  background: transparent;
  color: transparent; }

.slick-next:hover, .slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before {
  opacity: 1; }

.slick-next:hover:before, .slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: #000;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  @media screen and (min-width: 48em) {
    .slick-prev {
      left: -50px; } }

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px; }

.slick-prev:before {
  content: "➞";
  display: block;
  transform: rotate(-180deg);
  margin-top: 2px; }

[dir="rtl"] .slick-prev:before {
  content: "➞"; }

.slick-next {
  right: -25px; }
  @media screen and (min-width: 48em) {
    .slick-next {
      right: -50px; } }

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto; }

.slick-next:before {
  content: "➞"; }

[dir="rtl"] .slick-next:before {
  content: "➞";
  display: block;
  transform: rotate(-180deg);
  margin-top: 2px; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
      .slick-dots li button:hover:before, .slick-dots li button:focus:before {
        opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  max-height: 250px; }
  @media screen and (min-width: 48em) {
    .slick-track {
      max-height: 400px; } }
  .slick-track:before {
    content: "";
    display: table; }
  .slick-track:after {
    content: "";
    display: table;
    clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide > img {
  display: block;
  width: 100%; }

.slick-slide.slick-loading > a img {
  display: none; }

.slick-slide.dragging > a img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

[data-slider-gallery] .slick-slide {
  margin: 0 10px; }
