@mixin bp($prop, $break1, $break2: '') {
  @if $prop == min {
    @media screen and (min-width: em($break1)) {
      @content; } }
  @else if $prop == max {
    @media screen and (max-width: em($break1)) {
      @content; } }
  @else if $prop == min-max {
    @media screen and (min-width: em($break1)) and (max-width: em($break2)) {
      @content; } } }

@mixin bp-h($prop, $break1, $break2: '') {
  @if $prop == min {
    @media screen and (min-height: em($break1)) {
      @content; } }
  @else if $prop == max {
    @media screen and (max-height: em($break1)) {
      @content; } }
  @else if $prop == min-max {
    @media screen and (min-height: em($break1)) and (max-height: em($break2)) {
      @content; } } }

@mixin bp-min-w-h($min-width, $min-height: '') {
  @media screen and (min-width: em($min-width)) and (min-height: em($min-height)) {
    @content; } }

@mixin unit($property, $factor: 1, $important: false) {
  @if $important {
    #{$property}: $grid-unit * $factor !important;
    @include bp(min, $grid-fluid-max-width) {
      #{$property}: ($grid-fluid-max-width / $grid-column-count) * $factor * 1px !important; } }
  @else {
    #{$property}: $grid-unit * $factor;
    @include bp(min, $grid-fluid-max-width) {
      #{$property}: ($grid-fluid-max-width / $grid-column-count) * $factor * 1px; } } }
