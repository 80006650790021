section {
  flex: 0 0 100vw;
  &:first-of-type {
    @include unit(padding-top, .75); } }
.section {
  @include unit(padding, .75);
  margin: 0 auto;
  @include unit(max-width, 12);

  @include bp(min, $bp-3) {
    @include unit(padding, .5);
    &:last-of-type:not('.section--dark') {
      @include unit(padding-bottom, 1); } } }
.section .textbox .textbox__image {}
.section:nth-of-type(even) .textbox .textbox__image {
  @include bp(max, $bp-3) {
    align-self: flex-end; } }
.section--half {
  margin: 0;
  flex: 0 0 100%;
  @include bp(min, $bp-3) {
    display: flex;
    flex: 0 0 50%; } }
.section--half .projects__item:only-of-type {
  @include bp(min, $bp-3) {
    @include unit(width, 4); } }
.section--collapse {
  padding: 0; }
.section--collapse-bottom {
  padding-bottom: 0; }
.section--dark {
  background-color: $black;
  color: $white;
  @include unit(padding-top, 1);
  @include unit(padding-bottom, 1);
  @include bp(min, $bp-3) {
    max-width: 100vw;
    @include unit(padding-top, .5);
    @include unit(padding-bottom, .5); } }
.section--grey {
  @include unit(padding-top, .75);
  background-color: #f8f7f7;
  @include bp(min, $bp-3) {
    max-width: 100vw; } }
.section--projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1285px;
  &:first-of-type {
    padding-bottom: 45px;
    @include bp(min, $bp-3) {
      padding-bottom: 90px; } } }
.section--icons {
  padding-top: 0;
  padding-bottom: 0; }
.section--no-pb {
  padding-bottom: 0 !important; }
