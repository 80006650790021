.has-debug-info {
  .grid-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: block;
    width: 100%;
    height: 100%;
    background-position: 0 0;
    background-image: linear-gradient(90deg, aqua 1px, transparent 1px);
    background-size: calc(#{$grid-unit} + 1px) 2px;
    overflow-x: hidden;
    pointer-events: none;
    @include bp(min, $grid-fluid-max-width) {
      @include transform(translateX(-50%));
      width: $grid-fluid-max-width * 1px;
      left: 50%;
      background-size: calc(#{$grid-fluid-max-width/$grid-column-count}px + 1px) 2px; }
    &:before,
    &:after {
      @include unit(width);
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      background-color: rgba(mintcream,0.4); }
    &:before {
      left: 0; }
    &:after {
      right: -6px; } }
  * {
    outline: 1px solid rgba(hotpink, 1); } }
