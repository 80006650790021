.boilerplate {
  display: flex;
  flex-wrap: wrap; }
.boilerplate--center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include bp(min, $bp-3) {
    align-items: center; } }
.boilerplate__headline {
  line-height: 1.6;
  font-weight: 400;
  font-size: 19px;
  @include unit(max-width, 3); }
.boilerplate__button {
  text-decoration: none;
  @include bp(min, $bp-3) {
    border-width: 1px;
    border-style: solid;
    border-color: rgba($white, .7);
    transition: border-color .25s ease;
    padding: 10px 20px; }
  &:hover {
    border-color: rgba($white, 1); } }
.boilerplate__link {
  text-decoration: none;
  @include unit(margin-bottom, 1);
  @include unit(width, 8);
  @include bp(min, $bp-3) {
    margin: 0;
    @include unit(width, 3);
    @include unit(margin-right, 1.5); }
  img:first-of-type {
    width: 100%; } }
.boilerplate__link--small {
  @include unit(width, 4.5);
  @include unit(margin-right, 1);
  @include bp(min, $bp-3) {
    margin: 0;
    @include unit(width, 1.5);
    @include unit(margin-right, .25);
    @include unit(margin-top, .5); }
  img:first-of-type {
    background-color: $white; } }
.boilerplate__link--small:last-child {
  margin: 0; }
