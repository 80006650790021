.slick-loading .slick-list {
  background: #fff url('../images/ajax-loader.gif') center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url('../fonts/slick.woff') format("woff");
  font-weight: normal;
  font-style: normal; }


/* Arrows */

.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }

.slick-prev {
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent; } }

.slick-next {
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent; } }

.slick-prev {
  &:hover:before, &:focus:before {
    opacity: 1; } }

.slick-next {
  &:hover:before, &:focus:before {
    opacity: 1; } }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  // font-family: "slick"
  font-size: 20px;
  line-height: 1;
  color: $black;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  @include bp(min, $bp-3) {
    left: -50px; } }
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px; }

.slick-prev:before {
  content: "➞";
  display: block;
  transform: rotate(-180deg);
  margin-top: 2px; }

[dir="rtl"] .slick-prev:before {
  content: "➞"; }

.slick-next {
  right: -25px;
  @include bp(min, $bp-3) {
    right: -50px; } }

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto; }

.slick-next:before {
  content: "➞"; }

[dir="rtl"] .slick-next:before {
  content: "➞";
  display: block;
  transform: rotate(-180deg);
  margin-top: 2px; }

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none; }
      &:hover:before, &:focus:before {
        opacity: 1; }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; } }
    &.slick-active button:before {
      color: black;
      opacity: 0.75; } } }

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none; }
  &.dragging {
    cursor: pointer;
    cursor: hand; } }

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  max-height: 250px;
  @include bp(min, $bp-3) {
    max-height: 400px; }
  &:before {
    content: "";
    display: table; }
  &:after {
    content: "";
    display: table;
    clear: both; } }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide {
  & > img {
    display: block;
    width: 100%; }
  &.slick-loading > a img {
    display: none; }
  &.dragging > a img {
    pointer-events: none; } }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

[data-slider-gallery] .slick-slide {
  margin: 0 10px; }
