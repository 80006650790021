.slider {
  margin: 0 auto;
  @include unit(max-width, 10);
  @include bp(min, $bp-2) {
    @include unit(max-width, 8); } }
.slider__item {
  & > a img {
    width: 100%; } }
.slider__headline {
  font-weight: 400;
  line-height: 1.1;
  font-size: 32px;
  position: relative;
  margin-bottom: -13px;
  z-index: 1; }
.slider__description {
  text-align: center;
  font-weight: 300;
  line-height: 1.6;
  @include unit(padding-top, .5);
  @include unit(padding-left, .5);
  @include unit(padding-right, .5); }
.slider--news {
  margin: 0 25px;
  @include bp(min, $bp-3) {
    margin: 0 50px; } }
.slider__link {
  display: block; }
