
$bp-0: 320;
$bp-1: 420;
$bp-2: 576;
$bp-3: 768;
$bp-4: 1024;
$bp-5: 1280;
$bp-6: 1400;
$bp-7: 1920;

$grid-column-count: 12;
$grid-unit: 100 / $grid-column-count * 1vw;
$grid-fluid-max-width: $bp-6;

$max-width--page: $grid-fluid-max-width;

$font-size-xs: 10;
$font-size-s: 14;
$font-size-m: 16;
$font-size-ml: 20;
$font-size-l: 30;
$font-size-xl: 40;
$font-size-xxl: 70;
$font-size-xxxl: 90;

$line-height-s: 1.3;
$line-height-m: 1.3;
$line-height-ml: 1.3;
$line-height-l: 1.2;
$line-height-xl: 1.2;
$line-height-xxl: 1.2;
$line-height-xxxl: 1.2;

$black: #000;

$white: #FFF;
$grey: #f1f1f1;
$bg--body: #FFF;

$border-underline: 0.06em solid currentColor;

$font-family: 'Graphik', Helvetica, Arial, sans-serif;
