.projects__item {
  display: block;
  @include unit(width, 8);
  @include unit(margin-bottom, 1);
  @include unit(padding-right, .25);
  @include unit(padding-left, .25);
  &:nth-child(odd) {
    @include unit(margin-left, -2.5); }
  &:nth-child(even) {
    @include unit(margin-right, -2.5); }
  @include bp(min, $bp-2) {
    &:nth-child(odd),
    &:nth-child(even) {
      margin: 0; }
    @include unit(width, 5);
    @include unit(margin-top, 1);
    &:nth-child(2n+2) {
      @include unit(width, 5);
      @include unit(padding-right, .75);
      @include unit(padding-left, .75);
      margin: 0;
      @include unit(margin-top, 1.5); }
    &:nth-child(4n-1) {
      @include unit(width, 5);
      @include unit(padding-right, .75);
      @include unit(padding-left, .75);
      @include unit(margin-top, 0); }
    &:nth-child(4n+4) {
      @include unit(width, 5);
      padding: 0;
      @include unit(padding-right, .25);
      @include unit(padding-left, .25);
      @include unit(margin-bottom, .5); }
    &:first-of-type {
      margin: 0; }
    &:last-child:nth-child(odd):not(:only-of-type) {
      margin-right: auto;
      @include unit(margin-left, .5); }
    &--border {
      @include unit(margin-left, 5); }
    &--border:nth-child(3) {
      text-align: right;
      a {
        display: inline-block; } } }
  &:only-of-type {
    margin: 0; } }
.projects__item--border {
  margin: 0 auto !important;
  margin-bottom: 70px !important;
  text-align: left !important; }
.projects__item-image {
  width: 100%; }
.projects__item-image--border {
  border: 2px solid $black;
  @include unit(max-width, 4); }
.projects__caption {
  display: table;
  width: 100%; }
.projects__caption-text {
  text-align: center;
  @include unit(margin-left, -0.5);
  @include unit(margin-right, -0.5); }
.projects__category-bar {
  height: 45px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998; }
.projects__category-bar-container {
  display: flex;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background-color: $black;
  color: $white;
  overflow-x: auto;
  @include bp(min, $bp-2) {
    overflow: hidden;
    justify-content: center; } }
.projects__category-bar-item {
  padding: 0 20px;
  font-size: 10px;
  text-decoration: none; }
.projects__category-bar-item--active {
  font-weight: 500; }
.projects__category-bar-item-divider {
  display: flex;
  height: 100%;
  align-items: center;
  &::before {
    content: '';
    display: inline-block;
    height: 1px;
    background: $white;
    vertical-align: middle;
    margin: 0 .5rem;
    width: 1rem;
    @include bp(min, $bp-3) {
      width: 2rem; } } }
.projects__tags {
  text-align: right;
  span {
    &::after {
      content: ' — '; } }
  span:last-of-type {
    &::after {
      content: ''; } } }
.projects__title {
  font-size: 16px;
  max-width: 85%;
  @include bp(min, $bp-3) {
    font-size: 22px;
    max-width: 80%; } }
.projects__table {
  width: 100%;
  margin-bottom: 4rem; }
.projects__list-item:not(.projects__list-item--leading) {
  border-bottom: 1px solid #eee; }
.projects__list-item--leading {
  border-collapse: collapse;
  font-style: italic;
  margin-bottom: 25px;
  font-weight: 500;
  & > div {
    padding-right: 25px;
    position: relative;
    span {
      display: inline-block;
      padding-bottom: 5px; }
    &:last-child {
      padding-right: 0; }
    &::after {
      content: '';
      display: block;
      background-color: $black;
      height: 1px; } }
  th {
    border-bottom: 1px solid $black;
    text-align: left;
    cursor: pointer; } }
.projects__list-link {
  border-collapse: collapse;
  font-size: $font-size-s + px;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  img {
    display: none; }
  &:hover {
    color: $black;
    background-color: $grey;
    img {
      display: inline;
      margin-left: 5px; } }
  td {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee; } }
.projects__item-title {
  padding-right: 5px;
  padding-left: 5px; }
.projects__item-city {
  padding: 5px; }
.projects__item-country {
  padding: 5px; }
.projects__item-year {
  padding: 5px; }
.projects__item-category {
  padding: 5px;
  text-transform: capitalize; }

// Responsive Table Styles

.projects__table,
.projects__table thead,
.projects__table tbody,
.projects__table th,
.projects__table td,
.projects__table tr {
  @include bp(min-max, $bp-0, $bp-4) {
    display: block; } }

.projects__table thead tr {
  @include bp(min-max, $bp-0, $bp-4) {
    position: absolute;
    top: -9999px;
    left: -9999px; } }

.projects__table td {
  @include bp(min-max, $bp-0, $bp-4) {
    position: relative;
    padding-left: 50%;
    &:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap; } } }

.projects__table td:nth-of-type(1):before {
  @include bp(min-max, $bp-0, $bp-4) {
    content: "Projekt";
    font-weight: 500;
    padding-top: 7px; } }

.projects__table td:nth-of-type(2):before {
  @include bp(min-max, $bp-0, $bp-4) {
    content: "Stadt";
    font-weight: 500;
    padding-top: 7px; } }

.projects__table td:nth-of-type(3):before {
  @include bp(min-max, $bp-0, $bp-4) {
    content: "Land";
    font-weight: 500;
    padding-top: 7px; } }

.projects__table td:nth-of-type(4):before {
  @include bp(min-max, $bp-0, $bp-4) {
    content: "Jahr";
    font-weight: 500;
    padding-top: 7px; } }

.projects__table td:nth-of-type(5):before {
  @include bp(min-max, $bp-0, $bp-4) {
    content: "Typ";
    font-weight: 500;
    padding-top: 7px; } }
