//needs refactoring; just a quick n dirty effect
.link--arrow {
  display: block;
  backface-visibility: hidden;
  text-decoration: none;
  img {
    transform: translateX(0);
    backface-visibility: hidden;
    transition: transform .25s ease; }
  &:hover {
    img {
      transform: translateX(2px); } } }
.link__arrow-image {
  width: 20px;
  vertical-align: middle; }
