.box {
  display: flex;
  flex-direction: column;
  @include bp(min, $bp-3) {
    flex-direction: row;
    flex-wrap: wrap; } }
// Size
.box--full {
  flex: 0 0 100%; }
.box--half {
  @include unit(width, 12);
  flex: 0 0 100%;
  @include bp(min, $bp-3) {
    @include unit(width, 6);
    flex: 0 0 50%;
    flex-direction: column; } }
.box--third {
  align-self: flex-start;
  @include unit(width, 8);
  @include unit(margin-left, 1);
  @include bp(min, $bp-3) {
    align-self: center;
    @include unit(width, 4);
    margin-left: initial; }
  z-index: 1; }
.box--fourth {
  @include unit(width, 6);
  align-self: flex-end;
  @include unit(margin-right, 1);
  @include bp(min, $bp-3) {
    @include unit(width, 2.75);
    margin-top: auto;
    margin-right: 0;
    align-self: center; }
  z-index: 1; }
// Alignment
.box--center-center {
  justify-content: center;
  align-items: center;
  align-content: center;
  @include bp(min, $bp-3) {
    justify-content: center;
    align-items: center;
    align-content: center; } }
.box--top-center {
  @include bp(min, $bp-3) {
    justify-content: center;
    align-items: flex-start; } }
.box--bottom-center {
  @include bp(min, $bp-3) {
    justify-content: flex-end;
    align-items: center;
    align-content: center; } }
.box--absolute-center {
  position: relative;
  @include bp(min, $bp-3) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 25vw;
    position: absolute;
    text-align: center; } }
.box--translate-half-right {
  z-index: 1;
  width: 100%;
  @include bp(min, $bp-3) {
    transform: translateX(50%);
    text-align: center; } }
.box--pull-down {
  @include bp(min, $bp-3); }
.box--pull-down .box--third {
  align-self: flex-end;
  @include unit(margin-right, 1);
  @include bp(min, $bp-3) {
    margin: 0;
    align-self: auto; } }
.box--start {
  @include unit(margin-bottom, 2); }

// Color
.box--grey {
  background-color: #f1f1f1;
  @include unit(padding-bottom, 1);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 0; }
  @include bp(min, $bp-3) {
    &::before {
      display: none; }
    min-height: 80vh; } }
// Borders
.box--border {
  border: 1px solid $black;
  padding: 5px;
  position: relative;
  img {
    display: block; } }

.box--border-right {
  @include bp(min, $bp-3) {
    border-right: 1px solid #000; } }
