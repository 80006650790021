.multicolumn {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  @include unit(max-width, 10);
  @include bp(min, $bp-3) {
    flex-direction: row; } }
.multicolumn__col {
  display: flex; }
.multicolumn__col--1of2:first-of-type {
  @include bp(min, $bp-3) {
    flex: 1 1 50%;
    @include unit(padding-right, .5); } }
.multicolumn__col--1of2 {
  @include bp(min, $bp-3) {
    flex: 1 1 50%;
    @include unit(padding-left, .5); } }
.multicolumn__col--1of3:first-of-type {
  @include bp(min, $bp-3) {
    flex: 1 1 40%; } }
.multicolumn__col--1of3 {
  @include bp(min, $bp-3) {
    flex: 1 1 30%; } }
.multicolumn__headline {
  @include bp(min, $bp-3) {
    flex: 1 1 100%;
    @include unit(padding-left, .5);
    @include unit(padding-right, .5); } }
.multicolumn__headline--span {
  @include bp(min, $bp-3) {
    @include unit(max-width, 8); } }
.multicolumn__col--1of2 .multicolumn__headline {
  @include bp(min, $bp-3) {
    padding: 0; } }
.multicolumn__list {
  flex: 1; }
.multicolumn__list-item {
  line-height: 1.6;
  margin-bottom: 25px;
  width: 100%;
  &:last-child {
    margin-bottom: 0; }
  p {
    font-weight: 400;
    display: inline-block; }

  p:last-of-type {
    margin-bottom: 5px; }
  & > a {
    display: block; } }
.multicolumn__list-item--single {
  margin-bottom: 10px; }
.multicolumn__list-item-headline {
  margin-bottom: 5px;
  display: flex !important;
  &::before {
    content: '';
    display: inline-block;
    height: 1px;
    background: #000;
    vertical-align: middle;
    margin-right: .5rem;
    flex: 0 0 1rem;
    margin-top: 12px;
    @include bp(min, $bp-3) {
      flex: 0 0 2rem; } } }
.multicolumn__list-item--single .multicolumn__list-item-headline {
  margin-bottom: 0; }
