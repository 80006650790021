* {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white; }

body {
  background-color: $bg--body;
  margin: 0;
  font-size: $font-size-m * 1px;
  font-family: $font-family;
  line-height: $line-height-m;
  color: $black;
  font-weight: normal;
  position: relative;
  overflow-x: hidden;
  padding: 0;
  display: flex;
  min-height: 100vh; }

h1, h2, h3, h4, h5, h6, blockquote, figure, ol, ul {
  font-weight: 500;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: bold; }

ul {
  list-style: none; }

ol, ul, p {
  padding: 0;
  margin: 0; }
p {
  line-height: 1.6; }
sup {
  line-height: 1; }
a {
  text-decoration: underline;
  color: inherit;
  &:hover {
    text-decoration: line-through; } }

img {
  max-width: 100%;
  height: auto;
  border: 0; }

iframe {
  border: 0; }

form {
  fieldset {
    border: 0;
    margin: 0;
    padding: 0; } }

select,
input {
  border-radius: 0;
  font-family: $font-family; }
input[type="text"],
input[type="email"] {
  @include appearance(none); }

textarea {
  vertical-align: top;
  font-family: $font-family; }

address, i, em {
  font-style: normal; }

 ::-webkit-input-placeholder {
  color: rgba($black, .75); }


 :-moz-placeholder {
  color: rgba($black, .75); }


 ::-moz-placeholder {
  color: rgba($black, .75); }


 :-ms-input-placeholder {
  color: rgba($white, .75); }

::-moz-selection {
  background-color: $black;
  color: $white;
  text-shadow: none; }

::selection {
  background-color: $black;
  color: $white;
  text-shadow: none; }
