.start__intro {
  display: inline-block;
  @include unit(max-width, 10);
  padding-top: 80px;
  @include bp(min, $bp-2) {
    padding-top: 100px; }
  @include bp(min, $bp-3) {
    @include unit(max-width, 6);
    padding-top: 130px; } }
.start__intro-copy {
  font-size: 22px;
  font-weight: normal;
  line-height: 1.4;
  @include unit(margin-bottom, .75);
  @include bp(min, $bp-3) {
    font-size: 30px;
    margin-bottom: 0; } }
.start__image {
  width: 100%;
  margin-bottom: 5px; }
.start__name {
  margin-left: .8em;
  @include unit(margin-bottom, 1.25);
  @include bp(min, $bp-3) {
    @include unit(margin-bottom, .25); } }
