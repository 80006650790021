.textbox {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @include bp(min, $bp-3) {
    flex-direction: row;
    flex-wrap: wrap; }
  p {
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 16px * 1.6; } }
.textbox__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
.textbox__headline {
  font-weight: 400;
  line-height: 1.4;
  font-size: 22px;
  @include bp(min, $bp-3) {
    font-size: 30px; } }
.textbox__headline--small {
  font-size: 19px;
  @include bp(min, $bp-3) {
    font-size: 22px; } }
.textbox--leading {
  p:first-of-type {
    &::before {
      content: '';
      display: inline-block;
      height: 1px;
      background: #000;
      vertical-align: middle;
      margin-right: .5rem;
      width: 1rem;
      @include bp(min, $bp-3) {
        width: 2rem; } } } }
.textbox--4of12 {
  @include bp(min, $bp-3) {
    @include unit(width, 4);
    @include unit(padding-left, .25);
    @include unit(padding-right, .25); } }
.textbox--5of12 {
  width: 100%;
  @include bp(min, $bp-3) {
    @include unit(width, 5);
    @include unit(padding-left, .25);
    @include unit(padding-right, .25); } }
.textbox__image {
  max-width: 100%;
  text-align: right;
  img {
    width: 100%; } }
.textbox__image .textbox__portrait-image {
  @include unit(max-width, 7);
  @include bp(min, $bp-3) {
    @include unit(max-width, 2.5); } }
.textbox__image .textbox__landscape-image {
  @include unit(max-width, 9);
  @include bp(min, $bp-3) {
    @include unit(max-width, 4); } }
.textbox__image ~ .has-image {
  @include unit(margin-top, 1); }
.textbox__image--right {
  text-align: left;
  @include bp(min, $bp-3) {
    order: 1; } }

// Styling for People Page and anchor nav
.people .textbox__headline--small {
  padding-top: 250px;
  margin-top: -250px; }
