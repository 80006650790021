.pagination {
  display: flex;
  flex: 0 0 100%;
  background-color: $black;
  justify-content: center;
  @include unit(padding, .75);
  @include bp(min, $bp-3) {
    @include unit(padding, .25); } }

.pagination__link {
  @include unit(width, 1);
  @include unit(height, 1);
  border: 1px solid $white;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 12px;
  font-weight: 500;
  transition: all .25s ease-in-out;
  text-decoration: none;
  @include unit(margin-left, .1);
  @include unit(margin-right, .1);
  @include bp(min, $bp-3) {
    width: 40px;
    height: 40px; } }

.pagination__link:hover,
.pagination__link--active {
  background-color: $white;
  color: $black; }

.pagination__link--dummy {
  pointer-events: none;
  border-color: $black; }
.pagination__link--prev,
.pagination__link--next {
  transform: rotate(0deg);
  img {
    transition: opacity .25s ease-in-out;
    max-width: 60%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    &:nth-child(2) {
      opacity: 0;
      visibility: hidden; } }
  &:hover {
    img:nth-child(2) {
      opacity: 1;
      visibility: visible; } } }
.pagination__link--prev {
  transform: rotate(-180deg); }
