.slide-up-transition {
  transition: all .3s ease;
  will-change: transform; }

.slide-up-enter, .slide-up-leave {
  transform: translateY(100%) translateZ(0.00000001px); }

.fade-transition {
  transition: opacity .3s ease; }

.fade-enter, .fade-leave {
  opacity: 0; }

@-webkit-keyframes uil-default-anim {
    0% {
      opacity: 1; }

    100% {
      opacity: 0; } }


@keyframes uil-default-anim {
    0% {
        opacity: 1; }

    100% {
        opacity: 0; } }


.uil-default-css > div:nth-of-type(1) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
    flex: 1 1 100%;
    padding: .5em 1em;
    margin: 1em auto;
    &.is-hidden {
      display: none; } }
.uil-default-css > div:nth-of-type(2) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.4166666666666667s;
    animation-delay: -0.4166666666666667s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(3) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.33333333333333337s;
    animation-delay: -0.33333333333333337s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(4) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(5) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.16666666666666669s;
    animation-delay: -0.16666666666666669s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(6) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.08333333333333331s;
    animation-delay: -0.08333333333333331s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(7) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0s;
    animation-delay: 0s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(8) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.08333333333333337s;
    animation-delay: 0.08333333333333337s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(9) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.16666666666666663s;
    animation-delay: 0.16666666666666663s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(10) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(11) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.33333333333333337s;
    animation-delay: 0.33333333333333337s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }

.uil-default-css > div:nth-of-type(12) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.41666666666666663s;
    animation-delay: 0.41666666666666663s; }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px; }
