// NOTE: refactor this mess please. k. thanks.

.headline__1 {}
.headline__2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: -12px;
  z-index: 1;
  position: relative;
  line-height: 1;
  @include bp(min, $bp-3) {
    font-size: 38px;
    margin-bottom: -15px; } }
.headline__3 {}

.headline__sub-s {
  line-height: 1.8;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase; }

.headline--slanted {
  font-size: 22px;
  font-weight: 400;
  font-style: italic;
  @include bp(min, $bp-3) {
    font-size: 38px; } }
