.header {
  background-color: $grey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex: 0 0 100%;
  flex-flow: column nowrap; }

.header__image {
  margin-top: -1em;
  margin-bottom: .5em;
  max-height: 300px;
  @include bp(min, $bp-2) {
    max-height: 400px; } }

.header--image {
  padding-top: 70px;
  padding-bottom: 50px;
  @include bp(min, $bp-2) {
    padding-top: 96px; } }
.header--small {
  height: 70px;
  padding: 0;
  h1 {
    margin-top: 55px; }
  @include bp(min, $bp-3) {
    min-height: 96px;
    h1 {
      margin-top: 70px; } } }
