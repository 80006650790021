/* NAVIGATION */

.nav {
  display: flex;
  @include unit(padding-left, .5);
  @include unit(padding-right, .5);
  align-items: center;
  z-index: 999;
  position: absolute;
  width: 100vw;
  height: 70px;
  flex: 0 0 auto;
  @include bp(min, $bp-2) {
    height: 96px; }
  &.is-sticky {
    position: fixed;
    background-color: $grey; } }
.nav__menu {
  margin-left: auto;
  @include bp(min, $bp-3); }
.nav__language-switcher {
  display: inline-block;
  @include unit(margin-right, .25);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  transition: opacity .15s ease;
  opacity: 1;
  text-decoration: none;
  &.is-hidden {
    opacity: 0; } }
.nav__home {
  display: flex;
  align-items: center;
  text-decoration: none;
  span {
    display: inline-block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    margin-left: 15px; } }
.nav__logo {
  width: 25px;
  display: block;
  @include bp(min, $bp-2) {
    width: 35px; }
  opacity: 1;
  transition: opacity 1s ease;
  &.is-hidden {
    opacity: 0; } }
.nav__hamburger {
  position: fixed;
  color: $white;
  display: block;
  max-height: 100vh;
  width: 90vw;
  transform: translateX(100%);
  top: 0;
  right: 0;
  transition: all .25s ease;
  z-index: 2;
  overflow-y: auto;
  visibility: hidden;
  will-change: transform;
  @include bp(min, $bp-2) {
    max-height: 100vh;
    width: 50vw; }
  @include bp(min, $bp-4) {
    width: 33.333vw; }
  &.is-active {
    transform: translateX(0);
    visibility: visible; } }
.nav__hamburger-content {
  @include unit(padding, 1);
  @include unit(padding-bottom, 2);
  background-color: $black;
  @include bp(min, $bp-3) {
    @include unit(padding, .5); } }
.nav__hamburger-header {
  background-color: transparent;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include unit(padding-right, .5);
  height: 70px;
  @include bp(min, $bp-2) {
    height: 96px; }
  @include bp(min, $bp-3) {
    @include unit(padding-right, .5); } }
.nav__hamburger-button {
  display: inline-block;
  opacity: 1;
  transition: opacity .15s ease;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    text-decoration: line-through; }
  &.is-hidden {
    opacity: 0; } }
.nav__hamburger-button--close {
  color: $black; }
.nav__hamburger-others-link {
  color: $black;
  margin-top: -50px; }
.nav__hamburger-item-list {
  margin-bottom: 30px; }
.nav__hamburger-item {
  font-size: 10px;
  font-weight: 500;
  line-height: 2;
  display: block;
  text-decoration: none; }
.nav__hamburger-headline {
  font-size: 22px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 15px;
  display: inline-block;
  text-decoration: none;
  &:first-child {
    margin-bottom: 30px;
    display: block; } }
.nav__hamburger-kontakt {
  text-align: center;
  align-self: flex-start;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #fff;
  line-height: 1.8;
  @include unit(padding-top, 1);
  @include unit(padding-bottom, 1);
  @include bp(min, $bp-3) {
    @include unit(padding-top, .5);
    @include unit(padding-bottom, .5); } }
.nav__hamburger-kontakt-maps-link,
.nav__hamburger-kontakt-email {
  display: inline-block;
  text-decoration: none; }
.nav__hamburger-kontakt-phone-mobile {
  text-decoration: none;
  @include bp(min, $bp-3) {
    display: none; } }
.nav__hamburger-kontakt-phone-desktop {
  display: none;
  @include bp(min, $bp-3) {
    text-decoration: none;
    display: block; } }
.nav__footer {
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; }
.nav__footer-item {
  font-size: 10px;
  text-decoration: none; }
.nav__footer-item:only-child {
  margin-left: auto; }
.nav__footer-item--back {
  img {
    transform: rotate(-180deg); } }
.nav__latest-news-image {
  margin-top: 20px; }
.nav__latest-news-image img {
  width: 100%; }
.nav__latest-news-title {
  font-size: initial; }
