.team__headline {
  text-align: center;
  width: 100%;
  @include bp(min, $bp-4) {
    flex: 0 0 100%;
    text-align: center; } }
.team__member-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  @include unit(max-width, 11);
  overflow-x: scroll;
  padding-bottom: 20px;
  @include bp(min, $bp-4) {
    flex-wrap: wrap;
    justify-content: center;
    @include unit(width, 10);
    padding: 0;
    overflow: auto; } }
.team__member {
  @include unit(min-width, 4.25);
  @include unit(padding-left, .25);
  @include unit(padding-right, .25);
  @include bp(min, $bp-2) {
    @include unit(min-width, 2.5); }
  @include bp(min, $bp-4) {
    min-width: auto;
    @include unit(width, 1.75);
    @include unit(padding-left, .25);
    @include unit(padding-right, .25);
    padding-top: 0;
    padding-bottom: 25px; } }
.team__member-image {
  width: 100%;
  margin-bottom: 5px; }
.team__boilerplate {
  @include unit(width, 4); }
.team__member-name {
  line-height: 1.2;
  font-size: 10px; }

// Anchor Nav

.anchor-nav ul {
  @include bp(min, $bp-3) {
    text-align: center; } }

.anchor-nav li {
  margin-bottom: 10px;
  font-weight: 400;
  @include bp(min, $bp-3) {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px; } }
